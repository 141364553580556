<template>
  <div
    :class="['report-table__cell-text report-table-link', {
      'report-table-link--disabled': isDisabled
    }]"
    @click.stop="handleOpenLink"
  >
    {{ cell.val }}
  </div>
</template>

<script>
import { access } from '@/mixins/access';
import { routeNames } from '@/config/router/router.config';
import { columnKeys } from '@/config/report/inventoryReport';
import { getFindInQuery } from '@/helpers/report/inventoryReport';
import { namespaceByRoute } from '@/config/report';

export default {
  name: 'ReportTableLink',
  mixins: [access],
  props: {
    column: {
      type: Object,
      required: true
    },
    cell: {
      type: Object,
      required: true
    },
    cellIndex: {
      type: Number,
      required: true
    },
    metadata: {
      type: Object,
      required: true
    },
    uniqueKey: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      linkHandlersMap: {
        [columnKeys.ITEMCODE]: this.openTreeNodeLink,
        [columnKeys.BOMTYPE]: this.openBomLink,
        [columnKeys.SOURCE_FROM]: this.openSourceFromLink
      }
    };
  },
  inject: [
    'openTableLink',
    'openTreeNodeLink',
    'openBomLink',
    'getCellContext'
  ],
  computed: {
    reportNamespace() {
      return namespaceByRoute[this.$sl_routeName];
    },
    isDisabled() {
      return !this.cell.val || this.cell.foreground !== 'link';
    }
  },
  methods: {
    handleOpenLink() {
      this.linkHandlersMap[this.column.columnKey]?.(this.getCellContext());
    },
    openSourceFromLink() {
      this.openTableLink({
        search: getFindInQuery(this.metadata.entity),
        route: routeNames.INVENTORY
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/report/table/inputs/report-table-link";
</style>