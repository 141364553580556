<template>
  <SlButton
    v-tooltip="colorInfoTooltip"
    size="2xs"
    variant="text"
    color="grey"
    :class="{
      'report-table__info-btn': single
    }"
  >
    {{ $t('Web.Report.ColorInfo') }}
    <template #append>
      <icon
        data="@icons/info.svg"
        class="fill-off size-16"
      />
    </template>
  </SlButton>
</template>

<script>
import { getInventoryInfoTooltip } from '@/helpers/shared/tooltip';

export default {
  name: 'ReportTableInfoButton',
  props: {
    single: Boolean
  },
  computed: {
    colorInfoTooltip() {
      return getInventoryInfoTooltip(this);
    }
  }
};
</script>

<style lang="scss" scoped>
.report-table__info-btn {
  margin: 6px 0 6px 20px;
}
</style>