<template>
  <div :class="['report-table__body-col', getColumnClasses()]">
    <div
      v-for="(cellValue, cellIndex) in column.cells"
      :key="cellIndex"
      v-tooltip="getTooltipValue(cellValue)"
      :data-row-index="cellIndex"
      :tabindex="cellIndex"
      :class="['report-table__cell', getCellClasses(cellValue, cellIndex)]"
      :data-test-id="`report-table-cell-${cellIndex}`"
      @focus="handleCellFocus({ cellIndex, cellValue })"
      @click.right="handleContextmenu({ cellIndex, cellValue }, $event)"
    >
      <template v-if="editable">
        <ReportTableDropdown
          v-if="isDropdownColumn"
          :cell="cellValue"
          :cell-index="cellIndex"
          :column="column"
          :metadata="rowsMetadata[cellIndex] || {}"
          :unique-key="uniqueKey"
          :disabled="isDisabled(cellValue, cellIndex)"
        />
        <ReportTableInput
          v-else
          :cell="cellValue"
          :cell-index="cellIndex"
          :column="column"
          :metadata="rowsMetadata[cellIndex] || {}"
          :unique-key="uniqueKey"
          :disabled="isDisabled(cellValue, cellIndex)"
        />
      </template>
      <ReportTableLink
        v-else-if="isLinkColumn"
        :cell="cellValue"
        :cell-index="cellIndex"
        :column="column"
        :metadata="rowsMetadata[cellIndex] || {}"
        :unique-key="uniqueKey"
        :disabled="isDisabled(cellValue, cellIndex)"
      />
      <template v-else>
        {{ cellValue.val }}
      </template>
    </div>
  </div>
</template>

<script>
import ReportTableDropdown from '@/components/Report/Table/Cells/ReportTableDropdown.vue';
import ReportTableInput from '@/components/Report/Table/Cells/ReportTableInput.vue';
import ReportTableLink from '@/components/Report/Table/Cells/ReportTableLink.vue';
import { access } from '@/mixins/access';
import { dropdownKeys, linkKeys, numberColumnTypes } from '@/config/report/inventoryReport';
import { inventoryCellFgs as fgs } from '@/config/shared/fgs.config';
import { getTooltip } from '@/helpers/shared/tooltip';

export default {
  name: 'ReportTableBodyCol',
  components: {
    ReportTableLink,
    ReportTableInput,
    ReportTableDropdown
  },
  mixins: [access],
  props: {
    column: {
      type: Object,
      required: true
    },
    rowsMetadata: {
      type: Array,
      required: true
    },
    selectedRows: {
      type: Object,
      required: true
    },
    uniqueKey: {
      type: String,
      required: true
    },
    editable: Boolean
  },
  inject: ['setCellContext'],
  computed: {
    isDropdownColumn() {
      return dropdownKeys.includes(this.column[this.uniqueKey]);
    },
    isNumberColumn() {
      return numberColumnTypes.includes(this.column.columnType);
    },
    isLinkColumn() {
      return linkKeys.includes(this.column[this.uniqueKey]);
    }
  },
  methods: {
    isDisabled(cell, index) {
      return !this.$sl_tabEditable || !(cell.fgs & fgs.EDITABLE) || !this.rowsMetadata[index]?.editable;
    },
    getTooltipValue({ tooltip }) {
      if (!tooltip) {
        return '';
      }

      return getTooltip({
        content: tooltip,
        type: tooltip.author ? 'note' : 'orders'
      });
    },
    getColumnClasses() {
      return {
        'report-table__body-col--simple': !this.isLinkColumn && !this.editable,
        'report-table__body-col--number': this.isNumberColumn
      };
    },
    getCellClasses(cellValue, cellIndex) {
      return {
        ...(!this.editable && {
          [`color--${cellValue.foreground}`]: cellValue.foreground,
          [`background--${cellValue.background}`]: cellValue.background
        }),
        'report-table__cell--selected': this.selectedRows[cellIndex] || this.rowsMetadata[cellIndex].enabled,
        'has-note': cellValue.tooltip
      };
    },
    getCellContext({ cellIndex, cellValue }) {
      return {
        col: this.column,
        [this.uniqueKey]: this.column[this.uniqueKey],
        cellValue,
        cellIndex,
        metadata: this.rowsMetadata[cellIndex] || {}
      };
    },
    handleContextmenu(payload, event) {
      this.$emit('contextmenu', event, this.getCellContext(payload));
    },
    handleCellFocus(payload) {
      this.setCellContext(this.getCellContext(payload));
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/report/table/report-table-body-col.scss";
</style>