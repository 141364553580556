<template>
  <SubPageWrapper
    width="full"
    no-padding
    relative
    data-shortcut-report
  >
    <ReportFilterModal />
    <ExportProgressModal />
    <NoteModal />
    <SubstituteItem />
    <RemoveFromImport />
    <CreateCollectionModal :id="modalsId.CREATE_COLLECTION" />
    <ExplainCalculation :id="modalsId.EXPLAIN_CALCULATION" />

    <template #loader>
      <SlOverlay
        :show="isPageInitializing"
        full-loader
        filled
      >
        <ReportSkeleton />
      </SlOverlay>
    </template>
    <template #filters>
      <FiltersToolbar v-if="table" />
    </template>

    <component
      :is="toolbarComponent"
      v-if="table"
    />

    <div class="report-table-wrapper">
      <ReportTable
        v-if="table"
        id="report-table"
        :columns="table.body.default"
        :pinned-columns="table.body.pinned"
        :headers="table.header.default"
        :pinned-headers="table.header.pinned"
        :loading="isTableUpdating"
        :rows-metadata="rowsMetadata"
        :selected-rows-count="selectedRowsCount"
        :total-rows-count="totalRowsCount"
        :current-page="page"
        :per-page="perPage"
        :initial-column-sizes="columnSizes"
        :sort-by="colClass"
        :sort-order="sortOrder"
        :editable="!isReports"
        :update-column-sizes="updateColumnSizes"
        @page-change="handlePaginationChange"
        @per-page-change="handlePerPageChange"
        @sort="handleSort"
        @selection="handleSelectMultipleRows"
      >
        <template #header-checkbox>
          <SlCheckbox
            :value="headerCheckboxValue"
            :indeterminate="isIndeterminate"
            @change="handleSelectRowsAll"
          />
        </template>

        <template #row-checkbox="{ rowIndex, toggle }">
          <SlCheckbox
            :value="isRowSelected(rowIndex)"
            @change="(state) => handleSelectRow({ rowIndex, state, toggle })"
          />
        </template>

        <template #no-data>
          <SlNoData>
            <template #image>
              <icon
                :data="noDataMeta.icon"
                :class="['illustration-md', {
                  'fill-off': search || activeFilterId !== -1
                }]"
              />
            </template>
            <template #title>
              {{ noDataMeta.title }}
            </template>
            <template #text>
              {{ noDataMeta.text }}
            </template>
          </SlNoData>
        </template>
      </ReportTable>
    </div>
  </SubPageWrapper>
</template>

<script>
import { mapActions } from 'vuex';
import CreateCollectionModal from '@/components/Modals/Collections/CreateCollectionModal.vue';
import ReportFilterModal from '@/components/Modals/Report/ReportFilterModal.vue';
import ExportProgressModal from '@/components/Modals/Report/ExportProgressModal.vue';
import FiltersToolbar from '@/components/Report/Toolbar/Filters/FiltersToolbar.vue';
import InventoryToolbar from '@/components/Report/Toolbar/Inventory/InventoryToolbar.vue';
import ForecastToolbar from '@/components/Report/Toolbar/Forecast/ForecastToolbar.vue';
import ReportTable from '@/components/Report/Table/Index.vue';
import RemoveFromImport from '@/components/Modals/SubstitutionRules/RemoveFromImport.vue';
import SubstituteItem from '@/components/Modals/SubstitutionRules/SubstituteItem.vue';
import NoteModal from '@/components/Modals/Shared/NoteModal.vue';
import ExplainCalculation from '@/components/Modals/ExplainCalculation.vue';
import ReportTableCellHeader from '@/components/Report/Table/ReportTableCellHeader.vue';
import ReportSkeleton from '@/components/Skeleton/ReportSkeleton.vue';
import { tableConfig } from '@/mixins/tableConfig';
import { cellActions } from '@/mixins/report/cellActions.mixin';
import { shortcut } from '@/mixins/shortcut';
import modalsId from '@/config/shared/modalsId.config';
import { namespaceByRoute } from '@/config/report';
import { routeNames } from '@/config/router/router.config';
import { DEFAULT_FILTER_ID } from '@/config/filter';
import { sendRequests } from '@/helpers/lastChange/sendRequests.js';

export default {
  name: 'Report',
  components: {
    ExplainCalculation,
    NoteModal,
    SubstituteItem,
    RemoveFromImport,
    ReportTableCellHeader,
    ReportTable,
    FiltersToolbar,
    InventoryToolbar,
    ForecastToolbar,
    CreateCollectionModal,
    ReportFilterModal,
    ExportProgressModal,
    ReportSkeleton
  },
  // will be fixed soon https://gmdhsoftware.atlassian.net/browse/WSL-3564
  // inject order fixing names duplication bug https://gmdhsoftware.atlassian.net/browse/WSL-3559
  mixins: [tableConfig, shortcut, cellActions],
  data() {
    return {
      modalsId,
      isPageInitializing: false,
      isTableUpdating: false,
      cellContext: null
    };
  },
  provide() {
    return {
      handleSort: this.handleSort,
      setCellContext: this.setCellContext,
      getCellContext: this.getCellContext,
      toggleIsTableUpdating: this.toggleIsTableUpdating
    };
  },
  computed: {
    reportNamespace() {
      return namespaceByRoute[this.$sl_routeName];
    },
    isReports() {
      return this.$sl_routeName === routeNames.REPORTS;
    },
    columnSizes() {
      return this.$store.state[this.reportNamespace]?.column_sizes || [];
    },
    table() {
      return this.$store.state[this.reportNamespace]?.table;
    },
    tableConfig() {
      return this.$store.state[this.reportNamespace]?.tableConfig;
    },
    activeFilterId() {
      return this.$store.state[this.reportNamespace]?.active_filter_id;
    },
    rowsMetadata() {
      return this.table.metadata.rowsMetadata;
    },
    totalRowsCount() {
      return this.table.metadata.totalRowsCount;
    },
    currentPage() {
      return this.table.metadata.currentPage;
    },
    toolbarComponent() {
      return this.$sl_routeName === routeNames.REPORTS ? ForecastToolbar : InventoryToolbar;
    },
    selectedRowsCount() {
      return this.table.metadata.selectedRowsCount;
    },
    headerCheckboxValue() {
      return !!this.selectedRowsCount || this.selectedRowsCount === this.totalRowsCount;
    },
    isIndeterminate() {
      return this.selectedRowsCount > 0 && this.selectedRowsCount < this.totalRowsCount;
    },
    noDataMeta() {
      if (this.search) {
        return {
          icon: require('@icons/style_no_search_results_double.svg'),
          title: this.$t('Web.NoData.SearchTitle'),
          text: this.$t('Web.NoData.SearchText')
        };
      }

      if (this.activeFilterId !== DEFAULT_FILTER_ID) {
        return {
          icon: require('@icons/style_no_search_results_double.svg'),
          title: this.$t('Web.NoData.FilterTitle'),
          text: this.$t('Web.NoData.FilterText')
        };
      }

      return {
        icon: require('@icons/illustration/no-table-rows.svg'),
        title: this.$t('Web.NoData.DefaultTitle')
      };
    }
  },
  async beforeMount() {
    try {
      this.isPageInitializing = true;

      await sendRequests(this.$sl_routeName);
    } finally {
      this.isPageInitializing = false;
    }
  },
  methods: {
    ...mapActions({
      updateRowsSelection: 'inventoryReport/updateRowsSelection'
    }),
    setCellContext(context) {
      this.cellContext = context;
    },
    getCellContext() {
      return this.cellContext;
    },
    toggleIsTableUpdating(value) {
      this.isTableUpdating = value;
    },
    fetchPage() {
      return this.$store.dispatch(`${this.reportNamespace}/fetchPage`);
    },
    updateTableConfig(config) {
      return this.$store.dispatch(`${this.reportNamespace}/updateTableConfig`, config);
    },
    updateColumnSizes(data) {
      this.$store.dispatch(`${this.reportNamespace}/updateColumnSizes`, data);
    },
    async updateTable() {
      try {
        this.isTableUpdating = true;

        await this.fetchPage();
      } finally {
        this.isTableUpdating = false;
      }
    },
    handlePaginationChange(page) {
      this.page = page;

      this.updateTable();
    },
    handlePerPageChange(pageSize) {
      this.perPage = pageSize;
      this.page = 1;

      this.updateTable();
    },
    getSelectedRowsId(items) {
      return Object.keys(items).reduce((acc, rowIndex) => {
        if (items[rowIndex]) {
          acc.push(this.rowsMetadata[+rowIndex].id);
        }

        return acc;
      }, []);
    },
    isRowSelected(index) {
      return this.rowsMetadata[index]?.enabled || this.rowsMetadata[index]?.cached;
    },
    async handleSelectRowsAll(state) {
      this.updateRowsSelection({
        state,
        global: true
      });
    },
    handleSelectRow({ rowIndex, state, toggle }) {
      toggle(state);

      this.updateRowsSelection({
        state,
        global: false,
        selected: [this.rowsMetadata[rowIndex].id]
      });
    },
    handleSelectMultipleRows(items) {
      this.updateRowsSelection({
        state: true,
        global: false,
        selected: this.getSelectedRowsId(items)
      });
    },
    async handleSort({ callback }) {
      const { key, order } = callback();

      this.sortOrder = order;
      this.colClass = key;

      try {
        this.isTableUpdating = true;

        await this.setSortingParams({
          config: this.tableConfig,
          params: {
            table: this.$store.getters[`${this.reportNamespace}/reportTable`](),
            type: this.$store.getters[`${this.reportNamespace}/reportType`]()
          }
        });
      } finally {
        this.isTableUpdating = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.report-table-wrapper {
  display: flex;
  flex-direction: column;
  height: calc(100% - 86px);
}
</style>
