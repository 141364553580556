<template>
  <div class="report-table-dropdown">
    <template v-for="entity in containerTypes">
      <SlTableSelect
        v-if="column[uniqueKey] === collectionsConfig[entity].key"
        :key="entity"
        :value="cell.val"
        :options="addNoneOption(collectionOptionsByType[entity])"
        :text-value="textValue"
        :disabled="isCollectionDisabled(entity)"
        :clearable="isOverride"
        :foreground="cell.foreground"
        type="row"
        :editable-bg="false"
        :allow-empty="isOverride"
        :max-height="maxHeight"
        open-direction="auto-height"
        focus-visible
        searchable
        @input="handleChangeSelect"
      >
        <template #clear="{ clear }">
          <SlButton
            v-tooltip="getTooltip($t('Main.CheckboxClearOv'))"
            size="xs"
            variant="tertiary"
            color="grey"
            class="sl-select__clear"
            icon
            @mousedown.prevent.stop="clear"
          >
            <icon
              data="@icons/close.svg"
              class="size-16"
            />
          </SlButton>
        </template>
        <template #afterList>
          <div
            class="report-table-dropdown__action"
            @click="handleCreateEntity(entity)"
          >
            {{ collectionsConfig[entity].label }}
          </div>
        </template>
      </SlTableSelect>
    </template>
    <SlTableSelect
      v-if="isDC"
      :value="cell.val"
      :options="addNoneOption(filterDcOptions(getOptions(DCOptions)))"
      :text-value="textValue"
      :disabled="disabled"
      :loading="isLoading"
      :show-no-options="isLoaded"
      :clearable="isOverride"
      :foreground="cell.foreground"
      type="row"
      :editable-bg="false"
      :allow-empty="isOverride"
      :max-height="maxHeight"
      open-direction="auto-height"
      focus-visible
      searchable
      @open="loadDCOptions"
      @input="handleChangeSelect"
    >
      <template #clear="{ clear }">
        <SlButton
          v-tooltip="getTooltip($t('Main.CheckboxClearOv'))"
          size="xs"
          variant="tertiary"
          color="grey"
          class="sl-select__clear"
          icon
          @mousedown.prevent.stop="clear"
        >
          <icon
            data="@icons/close.svg"
            class="size-16"
          />
        </SlButton>
      </template>
    </SlTableSelect>
    <SlTableSelect
      v-if="isSupplier"
      :value="cell.val"
      :options="addNoneOption(getOptions(supplierOptions))"
      :text-value="textValue"
      :loading="isLoading"
      :show-no-options="isLoaded"
      :clearable="isOverride"
      :foreground="cell.foreground"
      :max-height="maxHeight"
      open-direction="auto-height"
      type="row"
      :editable-bg="false"
      :allow-empty="isOverride"
      focus-visible
      searchable
      @open="loadSupplierOptions"
      @input="handleChangeSelect"
    >
      <template #clear="{ clear }">
        <SlButton
          v-tooltip="getTooltip($t('Main.CheckboxClearOv'))"
          size="xs"
          variant="tertiary"
          color="grey"
          class="sl-select__clear"
          icon
          @mousedown.prevent.stop="clear"
        >
          <icon
            data="@icons/close.svg"
            class="size-16"
          />
        </SlButton>
      </template>
    </SlTableSelect>
    <SlTableSelect
      v-if="isOrderingDays"
      ref="orderingDaysSelect"
      v-model="selectedOrderingDays"
      :options="getOptions(orderingDayOptions)"
      track-by="id"
      :text-value="textValue"
      :loading="isLoading"
      :show-no-options="isLoaded"
      :clearable="isOverride"
      :foreground="cell.foreground"
      type="row"
      :editable-bg="false"
      :close-on-select="false"
      :max-height="maxHeight"
      open-direction="auto-height"
      allow-empty
      focus-visible
      multiple
      @open="loadOrderingDayOptions"
      @close="handleChangeOrderingDays"
    >
      <template #selection>
        {{ ' ' }}
      </template>
      <template #clear>
        <SlButton
          v-tooltip="getTooltip($t('Main.CheckboxClearOv'))"
          size="xs"
          variant="tertiary"
          color="grey"
          class="sl-select__clear"
          icon
          @mousedown.prevent.stop="handleResetOrderingDays"
        >
          <icon
            data="@icons/close.svg"
            class="size-16"
          />
        </SlButton>
      </template>
      <template #selectOption="{ option, selected }">
        <div class="ordering-days__option">
          <SlCheckbox
            :value="selected"
            @click.native.prevent
          />
          <span>
            {{ option.name }}
          </span>
        </div>
      </template>
    </SlTableSelect>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { modal } from '@/mixins/modal';
import modalsId from '@/config/shared/modalsId.config';
import { columnKeys } from '@/config/report/inventoryReport';
import { containerTypes } from '@/config/report/inventoryReport/collections.config';
import { inventoryCellFgs as fgs } from '@/config/shared/fgs.config';
import { getTooltip } from '@/helpers/shared/tooltip';

export default {
  name: 'ReportTableDropdown',
  mixins: [modal],
  props: {
    column: {
      type: Object,
      required: true
    },
    cell: {
      type: Object,
      required: true
    },
    cellIndex: {
      type: Number,
      required: true
    },
    metadata: {
      type: Object,
      required: true
    },
    uniqueKey: {
      type: String,
      required: true
    },
    disabled: Boolean
  },
  data() {
    return {
      containerTypes,
      isLoading: true,
      isLoaded: true,
      DCOptions: [],
      supplierOptions: [],
      selectedOrderingDays: [],
      orderingDayOptions: [],
      collectionsConfig: {
        [containerTypes.GROUP]: {
          label: this.$t('CreateGroup.comboItemCreateGroup'),
          key: columnKeys.GROUP,
          fgs: fgs.SUPPORTS_GROUPS
        },
        [containerTypes.CONTAINER]: {
          label: this.$t('CreateContainer.comboItemCreateContainer'),
          key: columnKeys.CONTAINER,
          fgs: fgs.SUPPORTS_CONTAINERS
        },
        [containerTypes.MANUFACTURE_GROUP]: {
          label: this.$t('CreateGroup.comboItemCreateGroup'),
          key: columnKeys.BOM_GROUP,
          fgs: fgs.SUPPORTS_BOM_GROUPS
        }
      },
      EMPTY_VALUE: 'None',
      maxHeight: 280
    };
  },
  inject: ['processOverrideError'],
  computed: {
    ...mapGetters({
      getExistingIds: 'inventoryReport/collections/getExistingIds',
      getCreateData: 'inventoryReport/collections/getCreateData',
      collectionOptionsByType: 'inventoryReport/collections/collectionOptionsByType'
    }),
    isDC() {
      return this.column[this.uniqueKey] === columnKeys.DISTRIB_CENTER;
    },
    isSupplier() {
      return this.column[this.uniqueKey] === columnKeys.SUPPLIER;
    },
    isOrderingDays() {
      return this.column[this.uniqueKey] === columnKeys.ITEM_ORDERING_DAYS;
    },
    isOverride() {
      return this.cell.foreground === 'over';
    },
    isEmptyValue() {
      return this.cell.val === this.EMPTY_VALUE;
    },
    textValue() {
      if (!this.isOverride && this.isEmptyValue) {
        return '';
      }

      return this.cell.val;
    }
  },
  methods: {
    ...mapActions({
      updateOverride: 'inventoryReport/updateOverride',
      clearOverrides: 'inventoryReport/clearOverrides',
      fetchOrderingDays: 'inventoryReport/fetchOrderingDays',
      fetchSupplierNames: 'suppliers/fetchSupplierNames',
      createEntityAndSave: 'inventoryReport/collections/createEntityAndSave',
      fetchDCOptions: 'inventoryReport/fetchDCs'
    }),
    getTooltip,
    isCollectionDisabled(entity) {
      return this.disabled || !(this.cell.fgs & this.collectionsConfig[entity].fgs);
    },
    isNeedUpdateOrderingDays() {
      if (this.selectedOrderingDays === null) {
        return true;
      }

      const selected = this.selectedOrderingDays?.reduce((acc, item) => {
        if (item !== this.EMPTY_VALUE) {
          acc.push(item.id);
        }

        return acc;
      }, []) || [];
      const actual = this.orderingDayOptions.reduce((acc, option) => {
        if (option.isChecked) {
          acc.push(option.id);
        }

        return acc;
      }, []);

      return selected.length !== actual.length || selected.some((item, index) => item !== actual[index]);
    },
    getOptions(list) {
      if (list?.length) {
        return list;
      }

      if (this.cell.val && !this.isEmptyValue) {
        return [this.cell.val];
      }

      return [];
    },
    addNoneOption(options) {
      if (!options.length && !this.isOverride) {
        return [];
      }

      return [
        {
          value: this.EMPTY_VALUE,
          label: this.$t('Main.Lv.ByNone'),
          isSpecial: true
        },
        ...options
      ];
    },
    filterDcOptions(options) {
      return options.filter(option => option?.value !== this.metadata.entity.loc);
    },
    getOrderingDaysPayload() {
      return this.selectedOrderingDays?.reduce((acc, item) => {
        if (item !== this.EMPTY_VALUE) {
          acc.push(item.id);
        }

        return acc;
      }, []).join(',');
    },
    parseOptions(options) {
      if (!options?.length) {
        return [];
      }

      return options.map(option => ({
        label: option,
        value: option
      }));
    },
    async loadDCOptions() {
      try {
        if (this.DCOptions.length) {
          return;
        }

        this.isLoading = true;

        const options = await this.fetchDCOptions({
          item: this.metadata.entity.item
        });

        this.DCOptions = this.parseOptions(options);
      } finally {
        this.isLoading = false;
        this.isLoaded = true;
      }
    },
    async loadSupplierOptions() {
      try {
        if (this.supplierOptions.length) {
          return;
        }

        this.isLoading = true;

        const options = await this.fetchSupplierNames({
          item: this.metadata.entity.item
        });

        this.supplierOptions = this.parseOptions(options);
      } finally {
        this.isLoading = false;
        this.isLoaded = true;
      }
    },
    async loadOrderingDayOptions() {
      try {
        if (this.orderingDayOptions.length) {
          return;
        }

        this.isLoading = true;

        this.orderingDayOptions = await this.fetchOrderingDays({
          item: this.metadata.entity.item,
          location: this.metadata.entity.loc
        });

        this.selectedOrderingDays = this.cell.val === this.EMPTY_VALUE
          ? [this.EMPTY_VALUE]
          : this.orderingDayOptions.filter(option => option.isChecked);
      } finally {
        this.isLoading = false;
        this.isLoaded = true;
      }
    },
    handleCreateEntity(entity) {
      this.showModal(modalsId.CREATE_COLLECTION, {
        inputData: this.getCreateData(entity, this),
        existingIds: this.getExistingIds(entity),
        type: entity,
        createCallback: (item) => this.createEntityCallback(item, entity)
      });
    },
    async createEntityCallback(item, type) {
      await this.createEntityAndSave({
        type,
        ...item
      });

      this.updateOverride({
        ...(!this.metadata.enabled && { rowId: this.metadata.id }),
        [this.uniqueKey]: this.column[this.uniqueKey],
        val: item.identifier
      });
    },
    async handleChangeSelect(value) {
      if (this.cell.val === value) {
        return;
      }

      const oldForeground = this.cell.foreground;
      const oldValue = this.cell.val;

      this.$set(this.cell, 'foreground', 'auto');
      this.$set(this.cell, 'val', value);

      try {
        const action = value ? this.updateOverride : this.clearOverrides;

        await action({
          [this.uniqueKey]: this.column[this.uniqueKey],
          ...(!this.metadata.enabled && { rowId: this.metadata.id }),
          ...(value && { val: value !== this.EMPTY_VALUE ? value : '' })
        });
      } catch (e) {
        this.$set(this.cell, 'foreground', oldForeground);
        this.$set(this.cell, 'val', oldValue);

        this.processOverrideError({ e, isClear: !value });
      }
    },
    async handleChangeOrderingDays() {
      if (!this.isNeedUpdateOrderingDays()) {
        return;
      }

      try {
        const action = this.selectedOrderingDays ? this.updateOverride : this.clearOverrides;

        await action({
          ...(!this.metadata.enabled && { rowId: this.metadata.id }),
          [this.uniqueKey]: this.column[this.uniqueKey],
          ...(this.selectedOrderingDays && { val: this.getOrderingDaysPayload() })
        });
      } catch (e) {
        this.processOverrideError({ e, isClear: false });
      } finally {
        this.orderingDayOptions = [];
        this.selectedOrderingDays = [];
      }
    },
    handleResetOrderingDays() {
      this.selectedOrderingDays = null;

      this.$refs.orderingDaysSelect.toggle();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/report/table/inputs/report-table-dropdown";
</style>