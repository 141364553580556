<template>
  <SlGroupDropdownOption
    :title="title || $t('Web.Report.ShowColumns')"
    returnable
    @back="$emit('back')"
  >
    <template v-for="(option, optionIndex) in options">
      <SlAccordion
        v-if="option.list"
        :key="`${option[uniqueKey]}_${optionIndex}`"
        variant="action-list"
        class="checkbox-group"
      >
        <template #trigger="{ isOpen, toggle }">
          <div
            class="checkbox-group__trigger"
            @click="toggle"
          >
            <div class="body-1-md grey-80">
              {{ option.name }}
            </div>
            <icon
              data="@icons/chevron_down.svg"
              class="fill-off size-16"
              :class="{
                'rotate-180': isOpen
              }"
            />
          </div>
        </template>

        <SlDropdownOption
          v-for="(subOption, subOptionIndex) in option.list"
          :key="`${subOption[uniqueKey]}_${subOptionIndex}`"
        >
          <template #prepend>
            <SlCheckbox
              :id="`${subOption[uniqueKey]}_${subOptionIndex}`"
              :value="subOption.visible"
              @change="(state) => toggleVisibleCols(subOption, state)"
            />
          </template>
          <label :for="`${subOption[uniqueKey]}_${subOptionIndex}`">
            {{ subOption.name }}
          </label>
        </SlDropdownOption>
      </SlAccordion>
      <SlDropdownOption
        v-else
        :key="option[uniqueKey]"
      >
        <template #prepend>
          <SlCheckbox
            :id="option[uniqueKey]"
            :value="option.visible"
            @change="(state) => toggleVisibleCols(option, state)"
          />
        </template>
        <label :for="option[uniqueKey]">
          {{ option.name }}
        </label>
      </SlDropdownOption>
    </template>
  </SlGroupDropdownOption>
</template>

<script>
export default {
  name: 'ShowHideCategory',
  props: {
    title: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: () => ([])
    },
    uniqueKey: {
      type: String,
      required: true
    }
  },
  methods: {
    toggleVisibleCols(option, state) {
      this.$emit('toggle-visibility', {
        ...option,
        state
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.checkbox-group {
  ::v-deep {
    .checkbox-group__trigger {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      cursor: pointer;
    }

    .sl-accordion__content {
      max-height: fit-content;
    }

    .sl-accordion__content-inner {
      padding: 4px 12px;
    }
  }
}
</style>