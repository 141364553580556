var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{class:['forecast-widget__list', {
    'forecast-widget__list--column': _vm.column
  }]},_vm._l((_vm.widgets),function(widget){return _c('li',{key:widget.requestKey,class:['forecast-widget__item', {
      'forecast-widget__item--split': _vm.isSplitWidget(widget)
    }]},[(widget.type === _vm.controlType.select)?_c('SlSelect',{attrs:{"options":_vm.getOptions(widget.selection),"value":widget.val,"menu-z-index":"10001","select-label":_vm.getWidgetLabel(widget)},on:{"input":function (val) { return _vm.updateWidget(val, widget); }},scopedSlots:_vm._u([(_vm.isCustomLabelWidget(widget))?{key:"singleLabel",fn:function(){return [_c('span',{staticClass:"sl-select__label-key"},[_vm._v(" "+_vm._s(_vm.getCustomLabel(widget))+": ")]),_c('span',{staticClass:"sl-select__label-value"},[_vm._v(" "+_vm._s(_vm.getCustomValue(widget))+" ")])]},proxy:true}:null],null,true)}):(widget.type === _vm.controlType.comboBox)?_c('SlSelect',{staticClass:"sl-aggregation-select",attrs:{"value":widget.val,"options":_vm.getAggregationOptions(widget.selection),"close-on-select":false,"allow-empty":""},on:{"input":function (val) { return _vm.updateWidget(val, widget); }},scopedSlots:_vm._u([{key:"selection",fn:function(){return [_c('span',{staticClass:"sl-aggregation-select__label"},[_vm._v(" "+_vm._s(_vm.getCustomLabel(widget))+": ")]),_c('span',{class:['sl-aggregation-select__value', {
            'special': _vm.isLabelSpecial(widget)
          }]},[_vm._v(" "+_vm._s(_vm.getAggregationLabel(widget))+" ")])]},proxy:true},{key:"selectOption",fn:function(ref){
          var option = ref.option;
          var toggle = ref.toggle;
return [_c('div',{staticClass:"sl-aggregation-select__option",on:{"click":function($event){option.isSpecial && toggle()}}},[(!option.noCheckbox)?_c('SlCheckbox',{attrs:{"value":option.status}}):_vm._e(),_c('div',{class:['sl-aggregation-select__option-label', {
              'sl-aggregation-select__option-label--special': option.isSpecial
            }]},[_vm._v(" "+_vm._s(option.label)+" ")])],1)]}}],null,true)}):(widget.type === _vm.controlType.checkbox)?_c('SlCheckbox',{attrs:{"id":widget.requestKey,"disabled":!widget.enabled,"label":widget.name,"toggle":widget.requestKey === _vm.widgetKeys.ACTUAL_SALES_PERIODS_CHECKBOX},on:{"change":function (val) { return _vm.updateWidget(val, widget); }},model:{value:(widget.val),callback:function ($$v) {_vm.$set(widget, "val", $$v)},expression:"widget.val"}}):(widget.type === _vm.controlType.numberInput)?_c('SlControlInput',{attrs:{"value":widget.val,"disabled":!widget.enabled,"min":widget.min,"max":widget.max,"label":_vm.getControlInputLabel(widget)},on:{"input":function (val) { return _vm.updateWidget(+val || 1, widget); }}}):_vm._e()],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }