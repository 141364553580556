<template>
  <ToolbarWrapper v-if="widgets">
    <ForecastWidgets :widgets="contentWidgets" />

    <template #append>
      <ForecastWidgets :widgets="aggregationWidgets" />
      <ForecastOptionsDropdown />
    </template>
  </ToolbarWrapper>
</template>

<script>
import { mapState } from 'vuex';
import ToolbarWrapper from '@/components/Report/Toolbar/ToolbarWrapper.vue';
import ForecastOptionsDropdown from '@/components/Report/Toolbar/Forecast/ForecastOptionsDropdown.vue';
import ForecastWidgets from '@/components/Report/Toolbar/Forecast/ForecastWidgets.vue';
import { access } from '@/mixins/access';
import { contentWidgetKeys, widgetKeys } from '@/config/report/forecastReport.config';

export default {
  name: 'ForecastToolbar',
  components: {
    ForecastWidgets,
    ForecastOptionsDropdown,
    ToolbarWrapper
  },
  mixins: [access],
  computed: {
    ...mapState({
      widgets: (state) => state.forecastReport.toolbar
    }),
    contentWidgets() {
      return this.widgets.filter(({ requestKey }) => contentWidgetKeys.includes(requestKey));
    },
    aggregationWidgets() {
      return this.widgets.filter(({ requestKey }) => widgetKeys.AGGREGATE_KEYS === requestKey);
    }
  }
};
</script>