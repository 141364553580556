<template>
  <SlDropdown
    v-bind="$attrs"
    placement="bottom-start"
    :overflow-padding="5"
    @toggle="handleMenuToggle"
  >
    <template #target>
      <SlButton
        variant="tertiary"
        color="grey"
        icon
      >
        <icon
          data="@icons/burger.svg"
          class="fill-off size-20 color-grey-80"
        />
      </SlButton>
    </template>
    <template #content="{ close }">
      <div class="filters-list">
        <div class="filters-list__search">
          <SlSearchInput
            v-model="searchModel"
            :placeholder="$t('Web.Filters.SearchFilter')"
          />
        </div>
        <div
          v-if="filteredFiltersList.length"
          class="filters-list__content"
        >
          <SlDropdownOption
            v-for="(filter, filterIndex) in filteredFiltersList"
            :key="filter.id"
            :selected="filter.id === activeFilterId"
            select-close
            @select="setActiveFilterId(filter.id)"
          >
            <div
              v-tooltip.right="getFilterTooltip(filter)"
              class="ellipsis"
            >
              {{ filter.name }}
            </div>

            <template
              v-if="!checkIsRootFilter(filter)"
              #append
            >
              <FilterActionsDropdown
                :filter="filter"
                :filter-index="filterIndex"
                :action-callback="close"
              />
            </template>
          </SlDropdownOption>
        </div>
        <div
          v-else
          class="filters-list__no-data body-1 grey-60"
        >
          {{ $t('Web.Select.NoOptions') }}
        </div>
        <div class="filters-list__footer">
          <SlDropdownOption
            select-close
            @select="handleCreateFilter"
          >
            <template #prepend>
              <icon
                class="fill-off size-16 color-grey-80"
                data="@icons/plus.svg"
              />
            </template>
            <div class="grey-80">
              {{ $t('Web.Filters.CreateFilter') }}
            </div>
          </SlDropdownOption>
        </div>
      </div>
    </template>
  </SlDropdown>
</template>

<script>
import { modal } from '@/mixins/modal';
import FilterActionsDropdown from '@/components/Report/Toolbar/Filters/FilterActionsDropdown.vue';
import modalsId from '@/config/shared/modalsId.config';
import { namespaceByRoute } from '@/config/report';

export default {
  name: 'FiltersListDropdown',
  components: {
    FilterActionsDropdown
  },
  mixins: [modal],
  props: {
    filtersList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      searchModel: ''
    };
  },
  inject: [
    'setActiveFilterId',
    'checkIsRootFilter',
    'getFilterTooltip'
  ],
  computed: {
    reportNamespace() {
      return namespaceByRoute[this.$sl_routeName];
    },
    activeFilterId() {
      return this.$store.state[`${this.reportNamespace}`].active_filter_id;
    },
    filteredFiltersList() {
      return this.filtersList.filter(filter => filter.name.toLowerCase().includes(this.searchModel.toLowerCase()));
    }
  },
  methods: {
    handleCreateFilter() {
      this.showModal(modalsId.REPORT_FILTER);
    },
    handleMenuToggle() {
      // wait for dropdown to close
      setTimeout(() => {
        this.searchModel = '';
      }, 50);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/style/components/report/toolbar/filters-list';
</style>