import { exportTypes } from '@/config/report/inventoryReport';
import { widgetKeys } from '@/config/report/forecastReport.config';

export default function(widgets) {
  const data = {
    exportFormat: exportTypes.XLSX,
    forecastReportName: '',
    customRow: '',
    forecastPeriod: '',
    forecastReportAggregation: '',
    forecastReportUnits: ''
  };

  const fieldByRequestKey = {
    [widgetKeys.REPORT_TYPE]: 'forecastReportName',
    [widgetKeys.FORECAST_VERSION]: 'customRow',
    [widgetKeys.CUSTOM_FIELD]: 'customRow',
    [widgetKeys.ARCHIVE_PERIOD]: 'forecastPeriod',
    [widgetKeys.AGGREGATE_KEYS]: 'forecastReportAggregation',
    [widgetKeys.DISPLAY_IN]: 'forecastReportUnits'
  };

  widgets.forEach(({ requestKey, val }) => {
    const field = fieldByRequestKey[requestKey];

    if (requestKey === widgetKeys.CUSTOM_FIELD) {
      data[field] = 'custom row';

      return;
    }

    if (requestKey === widgetKeys.FORECAST_VERSION) {
      data[field] = JSON.parse(val)?.type || val;

      return;
    }

    if (field) {
      data[field] = val;
    }
  });

  return data;
}