<template>
  <SlContextMenu
    id="report-body-context-menu"
    ref="contextMenu"
    :options="availableOptions"
    @option-click="handleOptionClick"
  />
</template>

<script>
import { mapActions } from 'vuex';
import { modal } from '@/mixins/modal';
import {
  reportTableContextMenuOptions,
  reportTableContextMenuActionsMap,
  contextMenuKeys,
  fgsActionTypes
} from '@/config/shared/contextMenu.config';
import { filterByKeys, namespaceByRoute } from '@/config/report';
import { contextMenuFgs } from '@/config/shared/fgs.config';
import modalsId from '@/config/shared/modalsId.config';
import { routeNames } from '@/config/router/router.config';
import { columnKeys, numberColumnTypes } from '@/config/report/inventoryReport';
import { getFindInQuery } from '@/helpers/report/inventoryReport';
import { copyToClipboard } from '@/helpers/utils/clipboard';

export default {
  option: 'BodyContextMenu',
  mixins: [modal],
  data() {
    return {
      contextMenuKeys,
      contextMenuFgs,
      fgsActionTypes,
      options: reportTableContextMenuOptions(this),
      context: null
    };
  },
  inject: [
    'openTableLink',
    'openTreeNodeLink',
    'openBomLink',
    'substituteItem',
    'removeFromImport',
    'copyItemCode',
    'selectAll',
    'openExplainCalculation',
    'processOverrideError'
  ],
  computed: {
    reportNamespace() {
      return namespaceByRoute[this.$sl_routeName];
    },
    tableMetadata() {
      return this.$store.state[this.reportNamespace].table.metadata;
    },
    columnByColumnKeyMap() {
      return this.$store.getters[`${this.reportNamespace}/columnByColumnKeyMap`];
    },
    actionsFgs() {
      return {
        [fgsActionTypes.ENABLED]: this.context?.cellValue?.enabledAcs,
        [fgsActionTypes.VISIBLE]: this.context?.cellValue?.visibleAcs
      };
    },
    availableOptions() {
      return this.options.reduce((acc, option) => {
        if (option.fgs) {
          const visible = this.getActionFgs(option.fgs, fgsActionTypes.VISIBLE);

          if (!visible) {
            return acc;
          }

          acc.push({
            ...option,
            disabled: !this.getActionFgs(option.fgs, fgsActionTypes.ENABLED)
          });

          return acc;
        }

        if (option.key === contextMenuKeys.SELECT_ALL && !this.isSelectAllAvailable) {
          return acc;
        }

        acc.push(option);

        return acc;
      }, []);
    },
    isReports() {
      return this.$sl_routeName === routeNames.REPORTS;
    },
    isSelectAllAvailable() {
      return !this.isReports && this.tableMetadata.selectedRowsCount < this.tableMetadata.totalRowsCount;
    }
  },
  methods: {
    ...mapActions({
      clearOverrides: 'inventoryReport/clearOverrides',
      copyQtyToOverride: 'inventoryReport/copyQtyToOverride',
      resetNextOrderDate: 'inventoryReport/resetNextOrderDate'
    }),
    show({ context, event }) {
      this.context = context;
      this.$refs.contextMenu.show({ context, event });
    },
    handleOptionClick({ key, context }) {
      this[reportTableContextMenuActionsMap[key]]?.(context);
    },
    getActionFgs(action, type) {
      if (typeof action === 'bigint') {
        return !!(BigInt(this.actionsFgs[type] || 0) & action);
      }

      return !!(this.actionsFgs[type] & action);
    },
    getFilterValue({ cellValue, col }) {
      // todo refactor
      const { edit, val } = cellValue || {};

      if (!numberColumnTypes.includes(col?.columnType)) {
        return val ?? null;
      }

      const preparedValue = val?.replace(/[^0-9∞.—-]/g, '');

      if (edit) {
        return +edit;
      }

      if (!isNaN(+preparedValue)) {
        return +preparedValue;
      }

      return null;
    },
    getClearOverridesText({ metadata }) {
      if (!metadata.enabled || this.tableMetadata.selectedRowsCount <= 1) {
        return this.$t('Web.Modals.ActionConfirm.TextClearOverridesOne');
      }

      return this.$t('Web.Modals.ActionConfirm.TextClearOverridesMany', { 1: this.tableMetadata.selectedRowsCount });
    },
    async filterBy({ columnKey, ...context }) {
      await this.$store.dispatch(`${this.reportNamespace}/filterBy`, {
        filterBy: filterByKeys.COLUMN,
        val: this.getFilterValue(context),
        columnKey
      });

      this.showModal(modalsId.REPORT_FILTER);
    },
    async filterByRelatedBOMs({ metadata }) {
      const { item, loc: location } = metadata.entity;

      await this.$store.dispatch(`${this.reportNamespace}/filterBy`, {
        filterBy: filterByKeys.RELATED_BOMs,
        item,
        location
      });

      this.showModal(modalsId.REPORT_FILTER);
    },
    async addToFilter({ columnKey, ...context }) {
      await this.$store.dispatch(`${this.reportNamespace}/addToFilter`, {
        filterBy: filterByKeys.COLUMN,
        val: this.getFilterValue(context),
        columnKey
      });

      this.showModal(modalsId.REPORT_FILTER, {
        filterId: this.$store.state[`${this.reportNamespace}`].active_filter_id
      });
    },
    async findInInventory({ metadata }) {
      const { chan, ...entity } = metadata.entity;

      this.openTableLink({
        search: getFindInQuery(entity),
        route: routeNames.INVENTORY
      });
    },
    async findInReports({ metadata }) {
      this.openTableLink({
        search: getFindInQuery(metadata.entity),
        route: routeNames.REPORTS
      });
    },
    findInDemand(context) {
      this.openTreeNodeLink(context);
    },
    openBoms(context) {
      this.openBomLink(context);
    },
    copyCell({ cellValue }) {
      copyToClipboard(cellValue.val || '');
    },
    async editNote({ metadata, cellIndex }) {
      const item = this.columnByColumnKeyMap[columnKeys.ITEMCODE]?.cells?.[cellIndex];

      this.showModal(modalsId.ADD_NOTE, {
        ...(item.tooltip || {}),
        metadata: metadata.entity
      });
    },
    async confirmClearOverridesCallback({ metadata }) {
      try {
        await this.clearOverrides({
          ...(!metadata.enabled && { rowId: metadata.id })
        });

        this.$notify({
          type: 'success',
          title: this.$t('Web.Report.ClearOverridesSuccess')
        });
      } catch (e) {
        this.processOverrideError({ e, isClear: true });
      }
    },
    clearRowOverrides(context) {
      this.showModal(modalsId.SL_CONFIRM_MODAL, {
        icon: 'style_warning_double',
        title: this.$t('Web.Modals.ActionConfirm.TitleClearOverrides'),
        text: this.getClearOverridesText(context),
        confirmText: this.$t('Web.Modals.ActionConfirm.ButtonClear'),
        confirmCallback: () => this.confirmClearOverridesCallback(context)
      });
    },
    handleCopyQtyToOverride(context) {
      this.copyQtyToOverride({
        ...context,
        errorHandler: (e) => this.processOverrideError({ e, isClear: false })
      });
    },
    async resetNextOrderDateCallback(context) {
      try {
        await this.resetNextOrderDate(context);

        this.$notify({
          type: 'success',
          title: this.$t('Web.Report.ResetNextOrderDateSuccess')
        });
      } catch (e) {
        this.processOverrideError({ e, isClear: false });
      }
    },
    handleResetNextOrderDate(context) {
      this.showModal(modalsId.SL_CONFIRM_MODAL, {
        icon: 'style_warning_double',
        title: this.$t('Common.Warning'),
        text: this.$t('Web.Modals.TextConfirmResetNextOrderDate'),
        confirmText: this.$t('Web.Modals.ActionConfirm.ButtonClear'),
        confirmCallback: () => this.resetNextOrderDateCallback(context)
      });
    }
  }
};
</script>