<template>
  <SlModal
    :id="id"
    :width="1200"
    :title="title"
    :loading="isLoading || isInitializing"
    :updating="isUpdating"
    maximizable
    persistent
    @on-enter="handleCreate"
    @created="onCreated"
    @show="onShow"
    @hide="onHide"
  >
    <ValidationObserver
      ref="observer"
      class="modal-content reports-filter-modal"
    >
      <div class="reports-filter-modal__toolbar">
        <SlControl
          v-model="typeModel"
          :options="typeOptions"
          :label="$t('Web.Filters.FilterType')"
          :disabled="!isEditable"
        />
        <SlValidate
          v-slot="{ invalid }"
          rules="required"
        >
          <SlInput
            v-model="nameModel"
            :label="$t('Web.Filters.FilterName')"
            :is-invalid="invalid"
            :disabled="!isEditable"
          />
        </SlValidate>
      </div>

      <FilterBuilder :filter-id="filterId" />
    </ValidationObserver>

    <template #footer>
      <SlModalFooter>
        <SlButton
          variant="secondary"
          color="grey"
          @click="handleCancel"
        >
          {{ $t('Common.Cancel') }}
        </SlButton>
        <SlButton
          :disabled="!isEditable"
          @click="handleCreate"
        >
          {{ createLabel }}
        </SlButton>
      </SlModalFooter>
    </template>
  </SlModal>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import FilterBuilder from '@/components/Filter/FilterBuilder.vue';
import { modal } from '@/mixins/modal';
import { access } from '@/mixins/access';
import modalsId from '@/config/shared/modalsId.config';
import { filterTypes } from '@/config/filter';
import { getTooltip } from '@/helpers/shared/tooltip';
import { namespaceByRoute } from '@/config/report';

export default {
  name: 'ReportFilterModal',
  components: {
    FilterBuilder
  },
  mixins: [modal, access],
  data() {
    return {
      id: modalsId.REPORT_FILTER,
      typeModel: filterTypes.PRIVATE,
      nameModel: '',
      filterId: null,
      isLoading: false
    };
  },
  computed: {
    ...mapState({
      filter: state => state.filter.filter,
      isEditable: state => state.filter.filter?.editable,
      isUpdating: state => state.filter.is_updating,
      isInitializing: state => state.filter.is_initializing
    }),
    reportNamespace() {
      return namespaceByRoute[this.$sl_routeName];
    },
    typeOptions() {
      return [
        {
          label: this.$t('SelectFilter.Private'),
          value: filterTypes.PRIVATE
        },
        {
          label: this.$t('SelectFilter.Public'),
          value: filterTypes.PUBLIC,
          disabled: !this.$sl_tabEditable,
          tooltip: getTooltip({
            content: this.$t('Web.Filters.PublicFilterTooltip'),
            disabled: this.$sl_tabEditable,
            placement: 'bottom'
          })
        }
      ];
    },
    title() {
      return this.isEdit
        ? this.$t('Web.Filters.EditFilter')
        : this.$t('Web.Filters.CreateFilter');
    },
    createLabel() {
      return this.isEdit
        ? this.$t('Common.Save')
        : this.$t('Web.Modals.BtnCreate');
    },
    isEdit() {
      return this.filterId !== null;
    }
  },
  methods: {
    ...mapActions({
      fetchFilter: 'filter/fetchFilter',
      changeFilterCache: 'filter/changeFilterCache',
      saveFilterCache: 'filter/saveFilterCache',
      discardFilterCache: 'filter/discardFilterCache'
    }),
    async onShow() {
      if (!this.isEdit) {
        return this.setFields(this.filter);
      }

      const filter = await this.fetchFilter(this.filterId);

      this.setFields(filter);
    },
    onHide() {
      this.typeModel = filterTypes.PRIVATE;
      this.nameModel = '';
      this.filterId = null;
      this.isLoading = false;
    },
    setFields(filter) {
      if (!filter) {
        return;
      }

      const { access, name } = filter;

      this.typeModel = access || filterTypes.PRIVATE;
      this.nameModel = name || '';
    },
    setActiveFilterId(id) {
      this.$store.dispatch(`${this.reportNamespace}/setActiveFilterId`, id);
    },
    async handleCreate() {
      const isValid = await this.$refs.observer.validate();

      if (!isValid) {
        return;
      }

      try {
        this.isLoading = true;

        await this.changeFilterCache({
          access: this.typeModel,
          name: this.nameModel
        });

        const id = await this.saveFilterCache();

        if (!this.isEdit) {
          this.setActiveFilterId(id);
        }
      } finally {
        this.isLoading = false;

        this.hideModal(this.id);
      }
    },
    handleCancel() {
      this.discardFilterCache();

      this.hideModal(this.id);
    }
  }
};
</script>

<style lang="scss" scoped>

.reports-filter-modal {
  &__toolbar {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    margin-bottom: 24px;
  }
}
</style>
