<template>
  <div
    :class="['report-table__header', {
      'report-table__header--scrolled': scrolled
    }]"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'ReportTableHeader',
  props: {
    scrolled: Boolean
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/report/table/report-table-header.scss";
</style>