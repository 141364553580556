<template>
  <div
    :class="['report-table__cell', {
      'report-table__cell--editable': editable,
      'report-table__cell--selected': selected,
      'report-table__cell--numeration': numeration
    }]"
  >
    <slot
      v-if="isCheckboxShown"
      name="checkbox"
    />
    <slot v-else />
  </div>
</template>

<script>
export default {
  name: 'ReportTableCell',
  props: {
    editable: Boolean,
    selected: Boolean,
    numeration: Boolean,
    hovered: Boolean
  },
  computed: {
    isCheckboxShown() {
      return this.selected || (this.editable && this.hovered && this.$scopedSlots.checkbox);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/report/table/report-table-cell.scss";
</style>