<template>
  <SlModal
    :id="id"
    :width="420"
    no-header
    @on-enter="handleYes"
    @created="onCreated"
    @hide="onModalHide"
  >
    <div class="modal-content modal__title">
      <icon
        data="@icons/style_warning_double.svg"
        class="fill-off size-48"
      />
      <div class="unsaved-data__title heading-4-sb grey-90">
        {{ title }}
      </div>
      <SlAlert
        v-if="additionTitle"
        :text="additionTitle"
        type="info"
      />
    </div>
    <template #footer>
      <SlModalFooter>
        <SlButton
          variant="secondary"
          color="grey"
          full-width
          @click="handleNo"
        >
          {{ $t('Common.Cancel') }}
        </SlButton>
        <SlButton
          full-width
          @click="handleYes"
        >
          {{ $t('Common.Remove') }}
        </SlButton>
      </SlModalFooter>
    </template>
  </SlModal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { modal } from '@/mixins/modal';
import modalsId from '@/config/shared/modalsId.config';

export default {
  name: 'RemoveFromImport',
  mixins: [modal],
  data() {
    return {
      id: modalsId.REMOVE_FROM_IMPORT,
      activeItemCode: null
    };
  },
  computed: {
    ...mapGetters({
      itemClones: 'substitutions/itemClone'
    }),
    title() {
      return this.$t('Ban.Item', {
        1: this.activeItemCode
      });
    },
    additionTitle() {
      if (!this.itemClones) {
        return '';
      }

      const count = +this.itemClones?._count;
      const name = this.itemClones?._name;

      switch (count) {
        case 0:
          return '';
        case 1:
          return this.$t('Web.Notification.RemoveOne', {
            1: name
          });
        default: 
          return this.$t('Web.Notification.RemoveMany', {
            1: name,
            2: count - 1
          });
      }
    }
  },
  methods: {
    ...mapActions({
      fetchTree: 'demand/tree/fetchTree',
      removeFromImport: 'substitutions/removeFromImport'
    }),
    onCreated(props) {
      Object.keys(props).forEach(key => {
        if (props[key]) {
          this[key] = props[key];
        }
      });
    },
    onModalHide() {
      this.activeItemCode = null;
    },
    async handleYes() {
      await this.removeFromImport(this.activeItemCode);
      await this.fetchTree();
      this.hideModal(this.id);
    },
    handleNo() {
      this.hideModal(this.id);
    }
  }
};
</script>
<style lang="scss" scoped>
.modal__title {
  text-align: center;
}

.unsaved-data__title {
  margin-top: 12px;
}

::v-deep {
  .sl-alert {
    margin-top: 16px;

    &__text {
      text-align: justify;
    }
  }
}
</style>