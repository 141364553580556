export const rowSelection = {
  data() {
    return {
      selectedRows: {},
      startIndex: null,
      lastHoveredIndex: null,
      isDragging: false,
      isTableEvent: false
    };
  },
  mounted() {
    if (!this.editable) {
      return;
    }

    const table = this.$refs.reportTable;

    table.addEventListener('mousedown', this.handleMousedown);
    table.addEventListener('mousemove', this.handleMousemove, true);
    document.addEventListener('mouseup', this.handleMouseup);
  },
  beforeDestroy() {
    if (!this.editable) {
      return;
    }

    const table = this.$refs.reportTable;

    table.removeEventListener('mousedown', this.handleMousedown);
    table.removeEventListener('mousemove', this.handleMousemove, true);
    document.removeEventListener('mouseup', this.handleMouseup);
  },
  methods: {
    _getRowIndex(e) {
      const cell = e.target.closest('.report-table__cell');

      if (!cell) {
        return null;
      }

      const rowIndex = parseInt(cell.getAttribute('data-row-index'), 10);

      if (isNaN(rowIndex)) {
        return null;
      }

      return rowIndex;
    },
    _generateSelection(startIndex, endIndex, value = true) {
      const selection = {};
      const start = Math.min(startIndex, endIndex);
      const end = Math.max(startIndex, endIndex);

      for (let i = start; i <= end; i++) {
        selection[i] = value;
      }

      return selection;
    },
    _fillRangeSelection(startIndex, endIndex) {
      const selected = {
        ...this._generateSelection(this.lastHoveredIndex, endIndex, false),
        ...this._generateSelection(startIndex, endIndex)
      };

      this.selectedRows = Object.assign({}, this.selectedRows, selected);
    },
    updateSelection(index, value) {
      if (index !== undefined) {
        this.$set(this.selectedRows, index, value);
      }
    },
    ctrlRowSelect(e) {
      const index = this._getRowIndex(e);

      if (index === null) {
        return;
      }

      this.updateSelection(index, true);

      this.startIndex = index;
    },
    shiftRowSelect(e) {
      const index = this._getRowIndex(e);

      if (index === null) {
        return;
      }

      this._fillRangeSelection(this.startIndex, index);
    },
    handleMousedown(e) {
      this.isDragging = true;
      this.isTableEvent = e.target.closest('.report-table');

      if (e.ctrlKey || e.metaKey || e.shiftKey) {
        return;
      }

      const index = this._getRowIndex(e);

      if (index === this.startIndex) {
        return;
      }

      this.startIndex = index;
    },
    handleMousemove(e) {
      const index = this._getRowIndex(e);

      if (index === null || index === this.lastHoveredIndex) {
        return;
      }

      if (this.isDragging) {
        this._fillRangeSelection(this.startIndex, index);
      }

      this.lastHoveredIndex = index;
    },
    handleMouseup(e) {
      this.isDragging = false;

      if (!this.isTableEvent) {
        return;
      }

      this.isTableEvent = false;

      if (e.ctrlKey || e.metaKey) {
        e.stopPropagation();
        this.ctrlRowSelect(e);

        return this.$emit('selection', this.selectedRows);
      }

      if (e.shiftKey) {
        e.stopPropagation();
        this.shiftRowSelect(e);
      }

      const isSelection = this.startIndex !== null && this.startIndex !== this.lastHoveredIndex;

      if (!isSelection) {
        return;
      }

      // wait for the click event to be processed
      setTimeout(() => {
        // emit moved inside mouseup because click event intercepting by table inputs
        this.$emit('selection', this.selectedRows);
      });
    },
    setSelectedRows(selected) {
      this.selectedRows = selected;
    }
  }
};