import vueI18n from '@/plugins/vue-i18n';
import { toArray } from '@/helpers/utils/toArray';

export const getColEntryValue = (entry) => {
  if (!entry || !entry.val) {
    return '';
  }

  let value = '';
  const { val, _type } = entry;

  if (['unit', 'uint', 'int', 'double'].includes(_type)) {
    value = val;
  }

  if (['string', 'locString'].includes(_type)) {
    if (entry._loc === '1') {
      value = (vueI18n.tc(val.code) || val.fallback) ?? vueI18n.tc(val);
    } else {
      value = val;
    }
  }

  if (val.entry) {
    value += getColEntryValue(val.entry);
  }

  return value || '';
};

export const getColValueWithEntry = (col, codeProp) => {
  if (!col) {
    return '';
  }

  if (!col.entry) {
    if (!col[codeProp]) {
      return col.fallback || col._fallback || '';
    }

    return vueI18n.tc(col[codeProp]);
  }

  let entryLocaleParams = toArray(col.entry).reduce((acc, param, index) => {
    if (param.val?.entry) {
      acc[index + 1] = getColValueWithEntry(param.val, 'code');
    } else {
      acc[index + 1] = getColEntryValue(param);
    }

    return acc;
  }, {});

  if (col[codeProp] === 'ProjectChanges.Other.AffectedChannels') {
    entryLocaleParams = {
      1: entryLocaleParams[1],
      p1: vueI18n.tc(`${col[codeProp]}.p1`, entryLocaleParams[2], { n: entryLocaleParams[2] })
    };

    return vueI18n.tc(`${col[codeProp]}.val`, null, entryLocaleParams);
  }

  return vueI18n.tc(col[codeProp], null, entryLocaleParams);
};
