<template>
  <SubPageWrapper
    width="full"
    :show-alerts="false"
    class="report-skeleton"
    no-padding
  >
    <template #filters>
      <div class="report-skeleton-filters">
        <div class="report-skeleton-filters__list">
          <SlSkeletonLoader
            type="button"
            width="20px"
            height="20px"
          />
          <SlSkeletonLoader
            type="text-14"
            width="60px"
          />
          <SlSkeletonLoader
            type="text-14"
            width="150px"
          />
          <SlSkeletonLoader type="text-14" />
          <SlSkeletonLoader
            type="button"
            width="20px"
            height="20px"
          />
        </div>
        <SlSkeletonLoader
          type="divider"
          width="100%"
          no-margin
        />
      </div>
    </template>

    <div class="report-skeleton-toolbar">
      <div class="report-skeleton-toolbar__content">
        <SlSkeletonLoader
          type="input"
          width="200px"
          no-margin
        />
        <SlSkeletonLoader
          type="button"
          no-margin
        />
        <SlSkeletonLoader
          type="button"
          no-margin
        />
      </div>
      <div class="report-skeleton-toolbar__content">
        <SlSkeletonLoader
          type="button"
          width="150px"
          no-margin
        />
        <SlSkeletonLoader
          type="button"
          width="32px"
          no-margin
        />
      </div>
    </div>

    <div class="report-skeleton-table">
      <div class="report-skeleton-table__row report-skeleton-table__row--header">
        <div class="report-skeleton-table__cell">
          <SlSkeletonLoader
            class="report-skeleton-table__cell--text"
            width="24px"
          />
          <SlSkeletonLoader
            type="divider"
            width="48px"
            no-margin
          />
        </div>
        <div
          v-for="column in 15"
          :key="column"
          class="report-skeleton-table__cell"
        >
          <SlSkeletonLoader class="report-skeleton-table__cell--text" />
          <SlSkeletonLoader
            type="divider"
            width="130px"
            no-margin
          />
        </div>
      </div>
      <div
        v-for="row in 30"
        :key="row"
        class="report-skeleton-table__row"
      >
        <div class="report-skeleton-table__cell">
          <SlSkeletonLoader width="24px" />
          <SlSkeletonLoader
            type="divider"
            width="48px"
            no-margin
          />
        </div>
        <div
          v-for="column in 15"
          :key="column"
          class="report-skeleton-table__cell"
        >
          <SlSkeletonLoader />
          <SlSkeletonLoader
            type="divider"
            width="130px"
            no-margin
          />
        </div>
      </div>
    </div>
  </SubPageWrapper>
</template>

<script>
export default {
  name: 'ReportSkeleton'
};
</script>

<style lang="scss" scoped>
@import "@/style/components/skeleton/report-skeleton";
</style>
