<template>
  <SlTableInput
    v-bind="inputProps"
    @submit="handleInputSubmit"
  />
</template>

<script>
import { mapActions } from 'vuex';
import { columnTypes } from '@/config/report/inventoryReport';
import { EMPTY_CACHE_OVERRIDE } from '@/config/shared/magicValues.config';
import regExp from '@/helpers/utils/regExp';

export default {
  name: 'ReportTableInput',
  props: {
    column: {
      type: Object,
      required: true
    },
    cell: {
      type: Object,
      required: true
    },
    cellIndex: {
      type: Number,
      required: true
    },
    metadata: {
      type: Object,
      required: true
    },
    uniqueKey: {
      type: String,
      required: true
    },
    disabled: Boolean
  },
  inject: ['processOverrideError'],
  computed: {
    inputProps() {
      const defaultProps = {
        value: this.cell.edit ?? this.cell.val,
        textValue: this.cell.val,
        disabled: this.disabled,
        editableBg: false,
        background: this.cell.background,
        foreground: this.cell.foreground
      };

      if (this.column.columnType === columnTypes.INTEGER) {
        return {
          ...defaultProps
        };
      }

      if (this.column.columnType === columnTypes.FLOAT) {
        return {
          ...defaultProps,
          inputRegexp: regExp.floatInput,
          submitRegexp: regExp.floatSubmit
        };
      }

      return {
        ...defaultProps,
        type: 'text',
        maxLength: 500
      };
    }
  },
  methods: {
    ...mapActions({
      updateOverride: 'inventoryReport/updateOverride',
      clearOverrides: 'inventoryReport/clearOverrides'
    }),
    async handleInputSubmit(value) {
      const oldForeground = this.cell.foreground;
      const oldValue = this.cell.val;
      const isImported = this.cell.foreground !== 'over';

      if (isImported && value === null) {
        this.$set(this.cell, 'val', oldValue);
      } else {
        this.$set(this.cell, 'foreground', 'auto');
        this.$set(this.cell, 'val', value);
      }

      try {
        const isEmptyValue = value === EMPTY_CACHE_OVERRIDE;
        const action = isEmptyValue ? this.clearOverrides : this.updateOverride;

        await action({
          [this.uniqueKey]: this.column[this.uniqueKey],
          ...(!this.metadata.enabled && { rowId: this.metadata.id }),
          ...(!isEmptyValue && { val: `${value}` })
        });
      } catch (e) {
        this.$set(this.cell, 'foreground', oldForeground);
        this.$set(this.cell, 'val', oldValue);

        this.processOverrideError({ e, isClear: !value });
      }
    }
  }
};
</script>
