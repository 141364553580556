<template>
  <SlGroupDropdown
    content-class="inventory-options-dropdown"
    @toggle="handleDropdownToggle"
  >
    <SlGroupDropdownTransition>
      <template #group>
        <div
          v-if="!isShowHideVisible"
          key="rootContent"
          class="group"
        >
          <SlGroupDropdownOption :title="$t('Web.Report.ViewOptions')">
            <SlDropdownOption
              round
              @select="toggleShowHide"
            >
              <template #prepend>
                <icon
                  data="@icons/table.svg"
                  class="fill-off size-16 color-grey-50"
                />
              </template>
              {{ $t('Web.Report.ShowColumns') }}
              <template #append>
                <span class="body-1 grey-50">
                  {{ $t('Web.Report.ColumnsShown', { 1: columnsVisibility.visibleCount }) }}
                </span>
                <icon
                  data="@icons/chevron_right.svg"
                  class="fill-off size-16 color-grey-60 "
                />
              </template>
            </SlDropdownOption>
          </SlGroupDropdownOption>
          <SlGroupDropdownOption
            :title="$t('Web.Report.ExportImport')"
            divider
          >
            <SlDropdown placement="left-start">
              <template #target>
                <SlDropdownOption round>
                  <template #prepend>
                    <icon
                      data="@icons/export_table.svg"
                      class="fill-off size-16 color-grey-50"
                    />
                  </template>
                  {{ $t('Web.Report.ExportTable') }}
                  <template #append>
                    <icon
                      data="@icons/chevron_right.svg"
                      class="fill-off size-16 color-grey-60 "
                    />
                  </template>
                </SlDropdownOption>
              </template>
              <template #content>
                <SlDropdownOption
                  select-close
                  @select="handleExportTableToXlsx"
                >
                  {{ $t('Main.Ui.acChooseExportToXlsx') }}
                </SlDropdownOption>
                <SlDropdownOption
                  :disabled="isExportToDatabaseDisabled"
                  select-close
                  @select="handleExportTableToDatabase"
                >
                  {{ $t('Main.Ui.acChooseExportToDatabase') }}
                </SlDropdownOption>
              </template>
            </SlDropdown>
            <SlDropdownOption
              select-close
              round
              @select="handleExportParameters"
            >
              <template #prepend>
                <icon
                  data="@icons/export.svg"
                  class="fill-off size-16 color-grey-50"
                />
              </template>
              {{ $t('Main.Ui.acExportSmallInvToXlsx') }}
            </SlDropdownOption>
            <SlUploader
              v-slot="{ open }"
              :accept="xlsxAccepted"
              @file-changed="handleImportParameters"
            >
              <SlDropdownOption
                :disabled="!$sl_tabEditable"
                round
                @select="open"
              >
                <template #prepend>
                  <icon
                    data="@icons/import.svg"
                    class="fill-off size-16 color-grey-50"
                  />
                </template>
                <template>
                  {{ $t('Main.Ui.acImportInventoryFromXlsx') }}
                </template>
              </SlDropdownOption>
            </SlUploader>
          </SlGroupDropdownOption>
          <SlGroupDropdownOption divider>
            <SlDropdown
              placement="left-start"
              content-class="color-info-dropdown"
            >
              <template #target>
                <SlDropdownOption round>
                  <template #prepend>
                    <icon
                      data="@icons/info.svg"
                      class="fill-off size-16"
                    />
                  </template>
                  {{ $t('Web.Report.ColorInfo') }}
                  <template #append>
                    <icon
                      data="@icons/chevron_right.svg"
                      class="fill-off size-16 color-grey-60 "
                    />
                  </template>
                </SlDropdownOption>
              </template>
              <template #content>
                <ul class="color-info-dropdown__content">
                  <li
                    v-for="({ text, type }) in colorInfoConfig"
                    :key="type"
                    class="color-info__item"
                  >
                    <i :class="type" />
                    —
                    <span>
                      {{ text }}
                    </span>
                  </li>
                </ul>
              </template>
            </SlDropdown>
          </SlGroupDropdownOption>
        </div>
      </template>
      <template #category>
        <ShowHideCategory
          v-if="isShowHideVisible"
          key="ShowHideCategory"
          class="category"
          :options="columnsVisibility.columns"
          unique-key="columnKey"
          @back="toggleShowHide"
          @toggle-visibility="handleToggleColumnVisibility"
        />
      </template>
    </SlGroupDropdownTransition>
  </SlGroupDropdown>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { hideAllPoppers } from 'floating-vue';
import ShowHideCategory from '@/components/Shared/OptionsMenu/ShowHideCategory.vue';
import SlGroupDropdownTransition from '@/components/UIKit/SlGroupDropdown/SlGroupDropdownTransition.vue';
import { access } from '@/mixins/access';
import { fileSaver } from '@/mixins/webAPI';
import { namespaceByRoute } from '@/config/report';
import modalsId from '@/config/shared/modalsId.config';
import { xlsxAccepted } from '@/config/utils/fileUpload.config';
import { supportedExportFgs } from '@/config/shared/fgs.config';
import { ampli } from '@/plugins/product/ampli';
import { getInventoryColorInfo } from '@/config/report/inventoryReport';

export default {
  name: 'InventoryOptionsDropdown',
  components: {
    SlGroupDropdownTransition,
    ShowHideCategory
  },
  mixins: [access, fileSaver],
  data() {
    return {
      xlsxAccepted,
      isShowHideVisible: false
    };
  },
  inject: [
    'handleExportTableToDatabase'
  ],
  computed: {
    ...mapGetters({
      canExport: 'project/canExport'
    }),
    reportNamespace() {
      return namespaceByRoute[this.$sl_routeName];
    },
    columnsVisibility() {
      return this.$store.state[`${this.reportNamespace}`]?.columns_visibility || {};
    },
    isExportToDatabaseDisabled() {
      // fgs inv = opt
      return !(this.canExport & supportedExportFgs.INVENTORY) || !this.$sl_hasAccess(this.$sl_features.exportTables);
    },
    colorInfoConfig() {
      return getInventoryColorInfo(this);
    }
  },
  methods: {
    ...mapActions({
      exportTableToXlsx: 'inventoryReport/exportTableToXlsx',
      exportParameters: 'inventoryReport/exportParameters',
      importParameters: 'inventoryReport/importParameters'
    }),
    toggleShowHide() {
      this.isShowHideVisible = !this.isShowHideVisible;
    },
    handleDropdownToggle() {
      this.isShowHideVisible = false;
    },
    handleToggleColumnVisibility(...args) {
      this.$store.dispatch(`${this.reportNamespace}/updateColumnVisibility`, ...args);
    },
    async handleExportTableToXlsx() {
      this.saveFile(this.exportTableToXlsx.bind(this));
    },
    async handleExportParameters() {
      this.saveFile(this.exportParameters.bind(this));
    },
    async handleImportParameters(file) {
      try {
        hideAllPoppers();

        await this.importParameters(file);
      } catch (e) {
        this.showModal(modalsId.SL_ERROR_MODAL, {
          title: this.$t('Main.Ui.acImportInventoryFromXlsx'),
          text: e.message
        });
      } finally {
        ampli.inventoryTableImported();
      }
    }
  }
};
</script>
<style lang="scss">
@import "@/style/components/report/inventory/inventory-options-dropdown.scss";
</style>
