<template>
  <SlContextMenu
    id="report-header-context-menu"
    ref="contextMenu"
    :options="availableOptions"
    @option-click="handleOptionClick"
  >
    <template #[contextMenuKeys.PIN_COLUMN]="{ handler, option }">
      <SlContextMenuOption
        :handler="handler"
        :option="option"
      >
        <template #prepend>
          <icon
            :data="getPinIcon(option)"
            class="size-16 fill-off"
          />
        </template>
        {{ option.name(isPinned) }}
      </SlContextMenuOption>
    </template>
    <template #[contextMenuKeys.SORT_COLUMN]="{ handler, option }">
      <SlDropdown
        v-if="!isDisabled"
        placement="right-start"
        :triggers="['hover']"
        :popper-triggers="['hover']"
        :distance="0"
      >
        <template #target>
          <SlContextMenuOption :option="option">
            {{ option.name }}
            <template #append>
              <icon
                data="@icons/chevron_right.svg"
                class="size-16 fill-off"
              />
            </template>
          </SlContextMenuOption>
        </template>
        <template #content>
          <SlContextMenuOption
            v-for="subOption in option.options"
            :key="subOption.order"
            v-close-popper
            :option="subOption"
            :handler="() => sortColumn(subOption.order)"
            tabindex="0"
          >
            {{ subOption.name }}
          </SlContextMenuOption>
        </template>
      </SlDropdown>
    </template>
  </SlContextMenu>
</template>

<script>
import {
  reportHeaderContextMenuOptions,
  reportHeaderContextActionsMap,
  contextMenuKeys
} from '@/config/shared/contextMenu.config';
import { DISABLED_STYLE, namespaceByRoute } from '@/config/report';

export default {
  name: 'HeaderContextMenu',
  data() {
    return {
      contextMenuKeys,
      options: reportHeaderContextMenuOptions(this),
      isPinned: false,
      isDisabled: false,
      columnKey: null
    };
  },
  inject: ['handleSort'],
  computed: {
    reportNamespace() {
      return namespaceByRoute[this.$sl_routeName];
    },
    headerByColumnKeyMap() {
      return this.$store.getters[`${this.reportNamespace}/headerByColumnKeyMap`];
    },
    availableOptions() {
      return this.options.reduce((acc, option) => {
        if (option.key === contextMenuKeys.SORT_COLUMN) {
          return this.isDisabled ? acc : [...acc, option];
        }

        return [...acc, option];
      }, []);
    }
  },
  methods: {
    toggleColumnPin(payload) {
      this.$store.dispatch(`${this.reportNamespace}/toggleColumnPin`, payload);
    },
    updateColumnVisibility(payload) {
      this.$store.dispatch(`${this.reportNamespace}/updateColumnVisibility`, payload);
    },
    show({ context, event }) {
      this.columnKey = context.header.columnKey;
      this.isPinned = this.headerByColumnKeyMap[this.columnKey].pinned;
      this.isDisabled = context.header.style === DISABLED_STYLE;
      this.$refs.contextMenu.show({ context, event });
    },
    getPinIcon({ icon }) {
      return require(`@icons/${icon(this.isPinned)}.svg`);
    },
    handleOptionClick({ key, context }) {
      const action = reportHeaderContextActionsMap[key];

      this[action] && this[action](context);
    },
    pinColumn({ header }) {
      this.toggleColumnPin({
        columnKey: header.columnKey,
        state: !this.isPinned
      });
    },
    hideColumn({ header }) {
      this.updateColumnVisibility({
        columnKey: header.columnKey,
        state: false
      });
    },
    sortColumn(order) {
      this.handleSort({
        callback: () => ({ order, key: this.columnKey })
      });
    }
  }
};
</script>