<template>
  <div class="modal-skeleton">
    <div class="modal-skeleton__header">
      <v-skeleton-loader
        type="text"
        max-width="30%"
      />
    </div>
    <div class="modal-skeleton__content">
      <slot />
    </div>
    <div class="modal-skeleton__footer">
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalSkeleton'
};
</script>

<style lang="scss" scoped>
@import "@/style/components/skeleton/modal/modal-skeleton.scss";
</style>
