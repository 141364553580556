import router from '@/router';

export const parseSearchQuery = (query = '') => {
  let parsedQuery = query;

  if (parsedQuery.includes('"')) {
    parsedQuery = parsedQuery.replace(/"/gi, '""');
  }

  if ([' ', ':'].some(char => parsedQuery.includes(char))) {
    parsedQuery = `"${parsedQuery}"`;
  }

  return parsedQuery;
};

const filterFindInQueries = (queries) => {
  if (queries.at(-1)) {
    return queries;
  }

  return filterFindInQueries(queries.slice(0, -1));
};

export const getFindInQuery = (data) => {
  if (!data) {
    return null;
  }

  const { item, loc, chan } = data;
  const queries = [item, loc, chan];

  return filterFindInQueries(queries).map(query => `"${query}"`).join(':');
};

export const getSearchQuery = (isLink) => {
  return isLink
    ? router.currentRoute.query.search
    : parseSearchQuery(router.currentRoute.query.search);
};
