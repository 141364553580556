<template>
  <div
    :class="['report-table__side-col', {
      'report-table__side-col--scrolled-left': scrolledLeft,
      'report-table__side-col--horizontal': horizontal
    }]"
  >
    <slot />
    <i class="report-table__side-col-shadow" />
  </div>
</template>

<script>
export default {
  name: 'ReportTableSideCol',
  props: {
    scrolledLeft: Boolean,
    horizontal: Boolean
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/report/table/report-table-side-col.scss";
</style>
