<template>
  <SlModal
    :id="id"
    :title="title"
    persistent
    @created="onCreated"
    @show="onShow"
    @hide="onHide"
  >
    <div class="note-modal modal-content">
      <div
        v-if="noteInfo"
        class="note-modal__info body-2 grey-80"
      >
        {{ noteInfo }}
      </div>
      <SlTextArea
        v-model="body"
        :placeholder="$t('EditNote.Ui.memo:placeholderText')"
        :maxlength="9999"
        height="96"
        bottom-round
        autofocus
      />
    </div>

    <template #footer>
      <SlModalFooter>
        <SlButton
          variant="secondary"
          color="grey"
          @click="handleCancel"
        >
          {{ $t('Common.Cancel') }}
        </SlButton>
        <SlButton
          :disabled="!isChanged"
          @click="handleSave"
        >
          {{ $t('Common.Save') }}
        </SlButton>
      </SlModalFooter>
    </template>
  </SlModal>
</template>

<script>
import { mapActions } from 'vuex';
import { modal } from '@/mixins/modal';
import modalsId from '@/config/shared/modalsId.config';
import { formatFullTime, utcTimezoneOffsetTime } from '@/helpers/date/date';

export default {
  name: 'NoteModal',
  mixins: [modal],
  data() {
    return {
      id: modalsId.ADD_NOTE,
      body: '',
      originalBody: '',
      author: '',
      editTime: '',
      metadata: null
    };
  },
  computed: {
    title() {
      if (this.editTime || this.author) {
        return this.$t('Main.EditNote');
      }

      return this.$t('Main.Ui.acToolbarNote');
    },
    noteInfo() {
      if (!this.editTime && !this.author) {
        return null;
      }

      return this.$t('EditNote.EditedBy', {
        1: formatFullTime(this.editTime),
        2: `UTC ${utcTimezoneOffsetTime}`,
        3: this.author
      });
    },
    isChanged() {
      return this.originalBody !== this.body;
    }
  },
  methods: {
    ...mapActions({
      updateNotice: 'note/updateNotice'
    }),
    onShow() {
      this.originalBody = this.body;
    },
    onHide() {
      this.body = '';
      this.originalBody = '';
      this.author = '';
      this.editTime = '';
      this.metadata = null;
    },
    handleSave() {
      this.updateNotice({
        text: this.isEmpty ? '' : this.body,
        metadata: this.metadata
      });

      this.hideModal(this.id);
    },
    handleCancel() {
      this.hideModal(this.id);
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/style/utils/color";
@import "@/style/utils/mixins";
@import "@/style/utils/variable";

.note-modal {
  &.modal-content {
    min-height: 100px;
    padding-top: 0;
  }

  &__info {
    margin: 8px 0 12px;
    padding: 6px 12px;

    background: $grey-10;
    border-radius: $border-radius-xs;
  }
}
</style>
