var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:['report-table__cell report-table__cell--header', {
    'report-table__cell--resizable': _vm.resizable,
    'report-table__cell--numeration': _vm.numeration,
    'report-table__cell--double': _vm.header.upperHeader,
    'report-table__cell--last-double': _vm.isLastDouble,
    'report-table__cell--editable': _vm.header.editable,
    'report-table__cell--disabled': _vm.isDisabled
  }],on:{"mouseenter":function($event){_vm.$scopedSlots.checkbox && _vm.toggleHovered(true)},"mouseleave":function($event){_vm.$scopedSlots.checkbox && _vm.toggleHovered(false)}}},[(_vm.header.upperHeader)?[(!_vm.isSameHeader)?_c('div',{staticClass:"report-table__cell-upper",style:({
        width: _vm.upperWidth
      })},[_c('div',{staticClass:"report-table__cell-text"},[_vm._v(" "+_vm._s(_vm.header.upperHeader.name)+" ")])]):_vm._e(),_c('div',{staticClass:"report-table__cell-lower"},[_vm._t("default")],2)]:(_vm.isCheckboxShown)?_vm._t("checkbox"):_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }