<template>
  <div
    :class="['report-table__cell report-table__cell--header', {
      'report-table__cell--resizable': resizable,
      'report-table__cell--numeration': numeration,
      'report-table__cell--double': header.upperHeader,
      'report-table__cell--last-double': isLastDouble,
      'report-table__cell--editable': header.editable,
      'report-table__cell--disabled': isDisabled
    }]"
    @mouseenter="$scopedSlots.checkbox && toggleHovered(true)"
    @mouseleave="$scopedSlots.checkbox && toggleHovered(false)"
  >
    <template v-if="header.upperHeader">
      <div
        v-if="!isSameHeader"
        class="report-table__cell-upper"
        :style="{
          width: upperWidth
        }"
      >
        <div class="report-table__cell-text">
          {{ header.upperHeader.name }}
        </div>
      </div>
      <div class="report-table__cell-lower">
        <slot />
      </div>
    </template>
    <slot
      v-else-if="isCheckboxShown"
      name="checkbox"
    />
    <slot v-else />
  </div>
</template>

<script>
import { DISABLED_STYLE } from '@/config/report';

export default {
  name: 'ReportTableCellHeader',
  props: {
    header: {
      type: Object,
      default: () => ({})
    },
    upperWidth: {
      type: String,
      default: '0'
    },
    editable: Boolean,
    resizable: Boolean,
    numeration: Boolean,
    isLastDouble: Boolean,
    showCheckbox: Boolean,
    isSameHeader: Boolean
  },
  data() {
    return {
      isHovered: false
    };
  },
  computed: {
    isCheckboxShown() {
      return this.editable && this.isHovered || this.showCheckbox;
    },
    isDisabled() {
      return this.header.style === DISABLED_STYLE;
    }
  },
  methods: {
    toggleHovered(isHovered) {
      this.isHovered = isHovered;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/report/table/report-table-cell.scss";
@import "@/style/components/report/table/report-table-cell-header.scss";
</style>