<template>
  <SlTabList
    v-model="tabModel"
    :tabs="filtersList"
    track-by="id"
    class="filters-toolbar"
    horizontal
    draggable
    scroll-to-active-tab
    @dragged="handleTabDragged"
  >
    <template #before-list>
      <FiltersListDropdown :filters-list="filtersList" />
    </template>
    <template #tab="{ tab, tabIndex, active, disabled, select }">
      <SlTab
        :active="active"
        :disabled="disabled"
        :drag-disabled="checkIsRootFilter(tab)"
        :class="['filters-toolbar__tab', {
          'filters-toolbar__tab--root': checkIsRootFilter(tab)
        }]"
        horizontal
        @mousedown.native.middle="handleFilterDelete(tab)"
        @click.native.stop="select"
      >
        <div
          v-tooltip.bottom="getFilterTooltip(tab)"
          class="ellipsis"
        >
          {{ tab.name }}
        </div>

        <template
          v-if="!checkIsRootFilter(tab)"
          #append
        >
          <FilterActionsDropdown
            :filter="tab"
            :filter-index="tabIndex"
          />
        </template>
      </SlTab>
    </template>
    <template #after-list>
      <SlButton
        v-tooltip.bottom="getTooltip($t('Web.Filters.CreateFilter'))"
        variant="tertiary"
        color="grey"
        icon
        @click="handleNewFilter"
      >
        <icon
          data="@icons/plus.svg"
          class="fill-off size-20 color-grey-80"
        />
      </SlButton>
    </template>
  </SlTabList>
</template>

<script>
import FilterActionsDropdown from '@/components/Report/Toolbar/Filters/FilterActionsDropdown.vue';
import FiltersListDropdown from '@/components/Report/Toolbar/Filters/FiltersListDropdown.vue';
import { modal } from '@/mixins/modal';
import { tableConfig } from '@/mixins/tableConfig';
import { namespaceByRoute } from '@/config/report';
import modalsId from '@/config/shared/modalsId.config';
import { DEFAULT_FILTER_ID } from '@/config/filter';
import { getTooltip } from '@/helpers/shared/tooltip';

export default {
  name: 'FiltersToolbar',
  components: {
    FiltersListDropdown,
    FilterActionsDropdown
  },
  mixins: [modal, tableConfig],
  data() {
    return {
      defaultTab: {
        id: DEFAULT_FILTER_ID,
        name: this.$t('Main.All')
      }
    };
  },
  provide() {
    return {
      setActiveFilterId: this.setActiveFilterId,
      checkIsRootFilter: this.checkIsRootFilter,
      getFilterTooltip: this.getFilterTooltip
    };
  },
  inject: ['toggleIsTableUpdating'],
  computed: {
    reportNamespace() {
      return namespaceByRoute[this.$sl_routeName];
    },
    tableConfig() {
      return this.$store.state[this.reportNamespace].tableConfig;
    },
    filtersList() {
      const list = this.$store.state[`${this.reportNamespace}`].filters_list;

      return [this.defaultTab, ...list];
    },
    activeFilterId() {
      return this.$store.state[`${this.reportNamespace}`].active_filter_id;
    },
    tabModel: {
      get() {
        return this.activeFilterId;
      },
      async set(value) {
        try {
          this.toggleIsTableUpdating(true);

          this.search = '';

          await this.setActiveFilterId(value);
        } finally {
          this.toggleIsTableUpdating(false);
        }
      }
    }
  },
  methods: {
    getTooltip,
    checkIsRootFilter({ id }) {
      return id === DEFAULT_FILTER_ID;
    },
    getFilterTooltip(filter) {
      if (this.checkIsRootFilter(filter)) {
        return;
      }

      return getTooltip(filter.name);
    },
    updateTableConfig(config) {
      return this.$store.dispatch(`${this.reportNamespace}/updateTableConfig`, config);
    },
    setActiveFilterId(filterId) {
      return this.$store.dispatch(`${this.reportNamespace}/setActiveFilterId`, filterId);
    },
    deleteFilter(filterId) {
      this.$store.dispatch(`${this.reportNamespace}/deleteFilter`, filterId);
    },
    updateFiltersOrder(filtersOrder) {
      this.$store.dispatch(`${this.reportNamespace}/updateFiltersOrder`, filtersOrder);
    },
    handleTabDragged(value) {
      this.updateFiltersOrder(value.slice(1));
    },
    handleNewFilter() {
      this.showModal(modalsId.REPORT_FILTER);
    },
    handleFilterDelete({ id, name, closable }) {
      if (!closable) {
        return;
      }

      this.showModal(modalsId.SL_CONFIRM_MODAL, {
        title: this.$t('Web.Modals.ActionConfirm.TitleDeleteFilter', { 1: name }),
        text: this.$t('Web.Modals.Delete.Description'),
        icon: 'style_warning_double',
        confirmText: this.$t('Web.Modals.ActionConfirm.ButtonDelete'),
        confirmCallback: () => this.deleteFilter(id)
      });
    }
  }
};
</script>

<style scoped lang="scss">
@import '@/style/components/report/toolbar/filters-toolbar';
</style>