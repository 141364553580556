<template>
  <ToolbarWrapper>
    <template #default="{ overflowed }">
      <SlDropdown
        v-if="overflowed"
        placement="bottom-start"
      >
        <template #target="{ isShown }">
          <SlButton
            variant="secondary"
            color="grey"
          >
            {{ $t('Web.Report.ManageInventory') }}
            <template #append>
              <icon
                data="@icons/chevron_down.svg"
                class="fill-off size-12"
                :class="{
                  'rotate-180': isShown
                }"
              />
            </template>
          </SlButton>
        </template>
        <template #content>
          <SlDropdownOption
            select-close
            @select="openInTransition"
          >
            <template #prepend>
              <icon
                data="@icons/in_transition.svg"
                class="fill-off size-16 color-grey-60"
              />
            </template>

            {{ $t('Main.Ui.acViewOnOrder') }}
          </SlDropdownOption>
          <SlDropdownOption
            v-if="displaySuppliers"
            select-close
            @select="openSuppliers"
          >
            <template #prepend>
              <icon
                data="@sidebar/suppliers.svg"
                class="fill-off size-16 color-grey-60"
              />
            </template>

            {{ $t('Web.Suppliers.PageTitle') }}
          </SlDropdownOption>
          <SlDropdownOption
            v-if="displayCollections"
            select-close
            @select="openContainers"
          >
            <template #prepend>
              <icon
                data="@icons/containers.svg"
                class="fill-off size-16 color-grey-60"
              />
            </template>

            {{ $t('Main.Ui.acEditCollectionsInv') }}
          </SlDropdownOption>
        </template>
      </SlDropdown>
      <template v-else>
        <SlButton
          variant="secondary"
          color="grey"
          @click="openInTransition"
        >
          <template #prepend>
            <icon
              data="@icons/in_transition.svg"
              class="fill-off size-16"
            />
          </template>

          {{ $t('Main.Ui.acViewOnOrder') }}
        </SlButton>
        <SlButton
          v-if="displaySuppliers"
          variant="secondary"
          color="grey"
          @click="openSuppliers"
        >
          <template #prepend>
            <icon
              data="@sidebar/suppliers.svg"
              class="fill-off size-16"
            />
          </template>

          {{ $t('Web.Suppliers.PageTitle') }}
        </SlButton>
        <SlButton
          v-if="displayCollections"
          variant="secondary"
          color="grey"
          @click="openContainers"
        >
          <template #prepend>
            <icon
              data="@icons/containers.svg"
              class="fill-off size-16"
            />
          </template>

          {{ $t('Main.Ui.acEditCollectionsInv') }}
        </SlButton>
      </template>
    </template>

    <template #append>
      <SlButton
        v-if="tableStats.length"
        v-tooltip.bottom="getTooltip({
          title: $t('Web.Report.TotalsInfo'),
          list: tableStats,
          type: 'totals'
        })"
        variant="text"
        color="grey"
      >
        <template #prepend>
          <icon
            data="@icons/total.svg"
            class="fill-off size-16"
          />
        </template>

        {{ $t('Web.Report.TotalsBtn') }}
      </SlButton>
      <SlButton
        v-if="displayConstrainedPlan"
        variant="secondary"
        color="grey"
        @click="openConstrainedPlan"
      >
        <template #prepend>
          <icon
            data="@icons/constrained_plan.svg"
            class="fill-off size-16"
          />
        </template>

        {{ $t('Web.ConstrainedPlan.PageTitle') }}
      </SlButton>
      <div
        v-tooltip="getTooltip({
          content: $t('Web.PlannedOrders.OverdatedTooltip'),
          disabled: !isOverdated
        })"
      >
        <SlButton
          :disabled="isOverdated"
          @click="openPlannedOrders"
        >
          <template #prepend>
            <icon
              data="@icons/planned_orders.svg"
              class="fill-off size-16"
            />
          </template>

          {{ $t('Main.Ui.acExportPeriodicToErp') }}
        </SlButton>
      </div>

      <InventoryOptionsDropdown />
    </template>
  </ToolbarWrapper>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import ToolbarWrapper from '@/components/Report/Toolbar/ToolbarWrapper.vue';
import InventoryOptionsDropdown from '@/components/Report/Toolbar/Inventory/InventoryOptionsDropdown.vue';
import { access } from '@/mixins/access';
import { plannedOrdersRoute } from '@/config/report/inventoryReport/plannedOrders.config';
import { inTransitionRoute } from '@/config/report/inventoryReport/inTransition.config';
import { routeNames } from '@/config/router/router.config';
import { namespaceByRoute } from '@/config/report';
import { getTooltip } from '@/helpers/shared/tooltip';

export default {
  name: 'InventoryToolbar',
  components: {
    InventoryOptionsDropdown,
    ToolbarWrapper
  },
  mixins: [access],
  computed: {
    ...mapState({
      isOverdated: state => state.project.project?.isOverdated
    }),
    ...mapGetters({
      hasCollections: 'project/hasCollections'
    }),
    reportNamespace() {
      return namespaceByRoute[this.$sl_routeName];
    },
    tableStats() {
      return this.$store.state[this.reportNamespace].table?.metadata?.stats ?? [];
    },
    displaySuppliers() {
      return this.$sl_hasAccess(this.$sl_features.accessSuppliers);
    },
    displayCollections() {
      return this.$sl_routeName !== routeNames.INTERSTORE && this.$sl_hasRouteAccess(routeNames.COLLECTIONS);
    },
    displayConstrainedPlan() {
      return this.$sl_routeName !== routeNames.INTERSTORE;
    }
  },
  methods: {
    getTooltip,
    openInTransition() {
      this.$router.push({
        name: inTransitionRoute[this.$sl_routeName]
      });
    },
    openSuppliers() {
      this.$router.push({
        name: routeNames.SUPPLIERS
      });
    },
    openContainers() {
      this.$router.push({
        name: routeNames.COLLECTIONS
      });
    },
    openConstrainedPlan() {
      this.$router.push({
        name: routeNames.CONSTRAINED_PLAN
      });
    },
    openPlannedOrders() {
      this.$router.push({
        name: plannedOrdersRoute[this.$sl_routeName]
      });
    }
  }
};
</script>