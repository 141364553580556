<template>
  <div
    ref="scrollContainer"
    class="toolbar-wrapper"
  >
    <div class="toolbar-wrapper__search">
      <SlSearchInput
        v-model="search"
        :placeholder="$t('Main.SearchStub')"
        expandable
        @submit="fetchPage"
      />
    </div>
    <div class="toolbar-wrapper__content">
      <slot
        v-bind="{
          overflowed: isOverflowed
        }"
      />
    </div>
    <div class="toolbar-wrapper__append">
      <slot name="append" />
    </div>
  </div>
</template>

<script>
import { modal } from '@/mixins/modal';
import { scroll } from '@/mixins/scroll';
import { tableConfig } from '@/mixins/tableConfig';
import { routeNames } from '@/config/router/router.config';
import modalsId from '@/config/shared/modalsId.config';
import { namespaceByRoute } from '@/config/report';
import { toggleUserInteraction } from '@/helpers/shared/webAPI';

export default {
  name: 'ToolbarWrapper',
  mixins: [modal, scroll, tableConfig],
  data() {
    return {
      contentWidth: null,
      isOverflowed: false
    };
  },
  provide() {
    return {
      handleExportTableToDatabase: this.handleExportTableToDatabase
    };
  },
  computed: {
    reportNamespace() {
      return namespaceByRoute[this.$sl_routeName];
    },
    tableConfig() {
      return this.$store.state[this.reportNamespace].tableConfig;
    }
  },
  watch: {
    clientWidth() {
      const containerNode = this.$refs.scrollContainer;
      const contentNode = containerNode?.querySelector('.toolbar-wrapper__content');

      if (!containerNode || !contentNode) {
        return;
      }

      if (containerNode.clientWidth < containerNode.scrollWidth && !this.isOverflowed) {
        this.contentWidth = contentNode.scrollWidth;
        this.isOverflowed = true;

        return;
      }

      if (this.isOverflowed && this.contentWidth <= contentNode.clientWidth) {
        this.contentWidth = null;
        this.isOverflowed = false;
      }
    }
  },
  methods: {
    fetchPage() {
      return this.$store.dispatch(`${this.reportNamespace}/fetchPage`);
    },
    exportTableToDatabase() {
      return this.$store.dispatch(`${this.reportNamespace}/exportTableToDatabase`);
    },
    updateTableConfig(config) {
      return this.$store.dispatch(`${this.reportNamespace}/updateTableConfig`, config);
    },
    handleExportTableToDatabase() {
      if (this.$sl_routeName === routeNames.INVENTORY) {
        this.exportToDatabaseWithProgress();
      } else {
        this.exportToDatabaseSimple();
      }
    },
    async exportToDatabaseSimple() {
      try {
        toggleUserInteraction(true);

        await this.$store.dispatch('operations/subscribe', {
          subscriber: this.exportTableToDatabase
        });

        this.$notify({
          type: 'success',
          title: this.$t('Main.Ui.acExportInventory'),
          text: this.$t('ExportToDatabase.Ok')
        });
      } catch (e) {
        this.$notify({
          type: 'error',
          title: this.$t('Main.Ui.acExportInventory'),
          text: e.message || this.$t('Error.DbInventoryExport'),
          duration: -1
        });
      } finally {
        toggleUserInteraction(false);
      }
    },
    exportToDatabaseWithProgress() {
      this.showModal(modalsId.EXPORT_REPORT_PROGRESS, {
        subscriber: this.exportTableToDatabase
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/report/toolbar/toolbar-wrapper.scss";
</style>