<template>
  <ModalSkeleton>
    <div class="explain-calculation-skeleton">
      <div class="explain-calculation-skeleton__toolbar">
        <div class="explain-calculation__toolbar--list">
          <v-skeleton-loader
            type="text"
            width="20%"
          />
          <v-skeleton-loader
            type="text"
            width="25%"
          />
          <v-skeleton-loader
            type="text"
            width="30%"
          />
          <v-skeleton-loader type="text" />
          <v-skeleton-loader type="text" />
          <v-skeleton-loader type="text" />
          <v-skeleton-loader
            type="text"
            width="50%"
          />
        </div>
        <div class="explain-calculation__toolbar--list">
          <v-skeleton-loader
            type="text"
          />
          <v-skeleton-loader
            type="text"
            width="30%"
          />
          <v-skeleton-loader
            type="text"
            width="10%"
          />
          <v-skeleton-loader
            type="text"
            width="30%"
          />
          <v-skeleton-loader
            type="text"
            width="20%"
          />
          <v-skeleton-loader
            type="text"
            width="20%"
          />
          <v-skeleton-loader
            type="text"
            width="30%"
          />
        </div>
      </div>
      <div class="explain-calculation-skeleton__main">
        <v-skeleton-loader
          type="heading"
          max-width="67%"
        />
        <v-skeleton-loader
          type="table-row@11"
        />
      </div>
    </div>
    <template #footer>
      <v-skeleton-loader
        type="button"
      />
    </template>
  </ModalSkeleton>
</template>

<script>
import ModalSkeleton from '@/components/Skeleton/Modals/ModalSkeleton.vue';

export default {
  name: 'ExplainCalculationSkeleton',
  components: {
    ModalSkeleton
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/skeleton/modal/explain-calculation-skeleton.scss";
</style>
