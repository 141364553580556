var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SlTabList',{staticClass:"filters-toolbar",attrs:{"tabs":_vm.filtersList,"track-by":"id","horizontal":"","draggable":"","scroll-to-active-tab":""},on:{"dragged":_vm.handleTabDragged},scopedSlots:_vm._u([{key:"before-list",fn:function(){return [_c('FiltersListDropdown',{attrs:{"filters-list":_vm.filtersList}})]},proxy:true},{key:"tab",fn:function(ref){
var tab = ref.tab;
var tabIndex = ref.tabIndex;
var active = ref.active;
var disabled = ref.disabled;
var select = ref.select;
return [_c('SlTab',{class:['filters-toolbar__tab', {
        'filters-toolbar__tab--root': _vm.checkIsRootFilter(tab)
      }],attrs:{"active":active,"disabled":disabled,"drag-disabled":_vm.checkIsRootFilter(tab),"horizontal":""},nativeOn:{"mousedown":function($event){if('button' in $event && $event.button !== 1){ return null; }return _vm.handleFilterDelete(tab)},"click":function($event){$event.stopPropagation();return select($event)}},scopedSlots:_vm._u([(!_vm.checkIsRootFilter(tab))?{key:"append",fn:function(){return [_c('FilterActionsDropdown',{attrs:{"filter":tab,"filter-index":tabIndex}})]},proxy:true}:null],null,true)},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:(_vm.getFilterTooltip(tab)),expression:"getFilterTooltip(tab)",modifiers:{"bottom":true}}],staticClass:"ellipsis"},[_vm._v(" "+_vm._s(tab.name)+" ")])])]}},{key:"after-list",fn:function(){return [_c('SlButton',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:(_vm.getTooltip(_vm.$t('Web.Filters.CreateFilter'))),expression:"getTooltip($t('Web.Filters.CreateFilter'))",modifiers:{"bottom":true}}],attrs:{"variant":"tertiary","color":"grey","icon":""},on:{"click":_vm.handleNewFilter}},[_c('icon',{staticClass:"fill-off size-20 color-grey-80",attrs:{"data":require("@icons/plus.svg")}})],1)]},proxy:true}]),model:{value:(_vm.tabModel),callback:function ($$v) {_vm.tabModel=$$v},expression:"tabModel"}})}
var staticRenderFns = []

export { render, staticRenderFns }