<template>
  <div
    ref="reportTable"
    class="report-table-wrapper"
    :class="{
      'report-table-wrapper--no-data': isNoData,
      'report-table-wrapper--pagination': showPagination
    }"
    :style="{
      height: tableHeight,
      maxHeight: `${maxHeight}px`
    }"
  >
    <HeaderContextMenu ref="headerContextMenu" />
    <BodyContextMenu ref="bodyContextMenu" />
    <SlOverlay :show="loading" />
    <div
      v-if="showTable"
      :id="id"
      class="report-table"
    >
      <!--   todo fix cell updating after mount /mixin/scroll  -->
      <DynamicScroller
        id="report-table-scroller"
        ref="scrollContainer"
        :key="rowsCount"
        :items="tableColumns"
        :key-field="uniqueKey"
        :min-item-size="columnWidth"
        direction="horizontal"
        :buffer="buffer"
        class="report-table__scroller"
        :style="{
          maxHeight: `${maxHeight}px`
        }"
        @scroll.native.passive="updateScroll"
      >
        <template #before>
          <ReportTableHeader :scrolled="hasVerticalScrollbar && !scrolledToTop">
            <ReportTableSideCol
              :scrolled-left="hasHorizontalScrollbar && !scrolledToLeft"
              horizontal
            >
              <ReportTableCellHeader
                :show-checkbox="!!selectedRowsCount"
                :editable="editable"
                numeration
              >
                <template #checkbox>
                  <slot name="header-checkbox" />
                </template>
              </ReportTableCellHeader>
              <template v-if="pinnedHeaders">
                <ReportTableCellHeader
                  v-for="(header, headerIndex) of pinnedHeaders"
                  :key="header[headerUniqueKey]"
                  :title="header[headerNameKey]"
                  :style="getColStyle(header.columnPosition)"
                  :data-resize="header.columnPosition"
                  :upper-width="calcUpperHeaderWidth(headerIndex, header.columnPosition, pinnedHeaders)"
                  :is-last-double="isLastDouble(headerIndex, header.columnPosition, pinnedHeaders)"
                  :is-same-header="isSameHeader(headerIndex, header.columnPosition, pinnedHeaders)"
                  :header="header"
                  :data-test-id="`${id}-header-${header[headerUniqueKey]}`"
                  resizable
                  @click.native="getColumnSortCallback($event, header)"
                  @click.right.native.stop.prevent="handleHeaderContextMenu(
                    $event,
                    {
                      header,
                      headerIndex
                    })"
                >
                  <div class="report-table__cell-text">
                    <span>
                      {{ header[headerNameKey] }}
                    </span>
                    <icon
                      v-if="sortBy === header[headerUniqueKey]"
                      data="@icons/arrow-up.svg"
                      class="size-12 report-table__cell-sort-icon"
                      :class="{
                        'report-table__cell-sort-icon--desc': sortOrder === sortingOrders.DESCENDING
                      }"
                    />
                  </div>
                </ReportTableCellHeader>
              </template>
            </ReportTableSideCol>
            <ReportTableCellHeader
              v-for="(header, headerIndex) of headers"
              :key="header[headerUniqueKey]"
              :title="header[headerNameKey]"
              :style="getColStyle(header.columnPosition)"
              :data-resize="header.columnPosition"
              :upper-width="calcUpperHeaderWidth(headerIndex, header.columnPosition, headers)"
              :is-last-double="isLastDouble(headerIndex, header.columnPosition, headers)"
              :is-same-header="isSameHeader(headerIndex, header.columnPosition, headers)"
              :header="header"
              :data-test-id="`${id}-header-${header[headerUniqueKey]}`"
              resizable
              @click.native="getColumnSortCallback($event, header)"
              @click.right.native.stop.prevent="handleHeaderContextMenu(
                $event,
                {
                  header,
                  headerIndex
                })"
            >
              <div class="report-table__cell-text">
                <span>
                  {{ header[headerNameKey] }}
                </span>
                <icon
                  v-if="sortBy === header[headerUniqueKey]"
                  data="@icons/arrow-up.svg"
                  class="size-12 report-table__cell-sort-icon"
                  :class="{
                    'report-table__cell-sort-icon--desc': sortOrder === sortingOrders.DESCENDING
                  }"
                />
              </div>
            </ReportTableCellHeader>
          </ReportTableHeader>
        </template>
        <template v-slot="{ item: col, index: colIndex, active }">
          <DynamicScrollerItem
            :item="col"
            :active="active"
            :data-index="colIndex"
          >
            <!--      empty template to keep virtual columns shift      -->
            <ReportTableSideCol
              v-if="isPinnedColumn(col[uniqueKey])"
              :style="colIndex && getColStyle(col.columnPosition)"
            >
              <ReportTableCell
                v-if="col[uniqueKey] === numerationKey"
                numeration
              />
              <ReportTableCell v-else />
            </ReportTableSideCol>
            <ReportTableBodyCol
              v-else
              :column="col"
              :selected-rows="selectedRows"
              :rows-metadata="rowsMetadata"
              :unique-key="uniqueKey"
              :editable="tableHeaders[colIndex - 1] && tableHeaders[colIndex - 1].editable"
              :style="getColStyle(col.columnPosition)"
              :data-test-id="`${id}-col-${tableHeaders[colIndex - 1] && tableHeaders[colIndex - 1][headerUniqueKey]}`"
              @contextmenu="handleBodyContextMenu"
            />
          </DynamicScrollerItem>
        </template>
        <!--    real pinned columns    -->
        <template #after>
          <ReportTableSideCol>
            <ReportTableCell
              v-for="(cellValue, cellIndex) in numerationColumn.cells"
              :key="cellIndex"
              :editable="rowsMetadata[cellIndex].editable && editable"
              :hovered="lastHoveredIndex === cellIndex"
              :selected="rowsMetadata[cellIndex].enabled || rowsMetadata[cellIndex].cached"
              :data-row-index="cellIndex"
              :tabindex="cellIndex - 1"
              numeration
            >
              {{ cellValue }}

              <template #checkbox>
                <slot
                  name="row-checkbox"
                  v-bind="{
                    rowIndex: cellIndex,
                    toggle: (value) => updateSelection(cellIndex, value)
                  }"
                />
              </template>
            </ReportTableCell>
          </ReportTableSideCol>

          <ReportTableSideCol
            :scrolled-left="hasHorizontalScrollbar && !scrolledToLeft"
            horizontal
          >
            <ReportTableBodyCol
              v-for="(col, colIndex) in pinnedColumns"
              :key="col.columnPosition"
              :column="col"
              :selected-rows="selectedRows"
              :rows-metadata="rowsMetadata"
              :unique-key="uniqueKey"
              :editable="tableHeaders[colIndex].editable"
              :style="getColStyle(col.columnPosition)"
              :data-test-id="`${id}-col-${tableHeaders[colIndex][headerUniqueKey]}`"
              @contextmenu="handleBodyContextMenu"
            />
          </ReportTableSideCol>
        </template>
      </DynamicScroller>
    </div>
    <SlTablePagination
      v-if="showPagination"
      :value="currentPage"
      :total-count="totalRowsCount"
      :selected="selectedRowsCount"
      :per-page="perPage"
      :disabled="loading"
      :show-per-page="false"
      @input="handlePageChange"
      @per-page="handlePerPageChange"
    >
      <template
        v-if="editable"
        #metadata
      >
        <ReportTableInfoButton />
      </template>
    </SlTablePagination>
    <slot
      v-if="isNoData"
      name="no-data"
    />
  </div>
</template>

<script>
import ReportTableCell from '@/components/Report/Table/ReportTableCell.vue';
import ReportTableBodyCol from '@/components/Report/Table/ReportTableBodyCol.vue';
import ReportTableHeader from '@/components/Report/Table/ReportTableHeader.vue';
import ReportTableCellHeader from '@/components/Report/Table/ReportTableCellHeader.vue';
import ReportTableSideCol from '@/components/Report/Table/ReportTableSideCol.vue';
import SlTablePagination from '@/components/UIKit/SlTable/SlTablePagination';
import HeaderContextMenu from '@/components/Report/Table/ContextMenu/HeaderContextMenu.vue';
import BodyContextMenu from '@/components/Report/Table/ContextMenu/BodyContextMenu.vue';
import ReportTableInfoButton from '@/components/Report/Table/ReportTableInfoButton.vue';
import { colResize } from '@/mixins/colResize';
import { scroll } from '@/mixins/scroll';
import { rowSelection } from '@/mixins/report/rowSelection.mixin';
import { sortingOrders } from '@/config/report/inventoryReport';
import { PER_PAGE_MAX } from '@/config/shared/slTable.config';
import { DISABLED_STYLE } from '@/config/report';

export default {
  name: 'ReportTable',
  components: {
    ReportTableInfoButton,
    BodyContextMenu,
    HeaderContextMenu,
    SlTablePagination,
    ReportTableSideCol,
    ReportTableCellHeader,
    ReportTableHeader,
    ReportTableBodyCol,
    ReportTableCell
  },
  mixins: [colResize, scroll, rowSelection],
  props: {
    id: {
      type: String,
      required: true
    },
    headers: {
      type: Array,
      default: () => ([])
    },
    pinnedHeaders: {
      type: Array,
      default: () => ([])
    },
    headerUniqueKey: {
      type: String,
      default: 'columnKey'
    },
    headerNameKey: {
      type: String,
      default: 'name'
    },
    columns: {
      type: Array,
      default: () => ([])
    },
    pinnedColumns: {
      type: Array,
      default: () => ([])
    },
    uniqueKey: {
      type: String,
      default: 'columnKey'
    },
    sortBy: {
      type: String,
      default: null
    },
    sortOrder: {
      type: String,
      default: null
    },
    maxHeight: {
      type: Number,
      default: 1600
    },
    columnWidth: {
      type: Number,
      default: 130
    },
    columnMinWidth: {
      type: Number,
      default: 40
    },
    initialColumnSizes: {
      type: Array,
      default: () => ([])
    },
    updateColumnSizes: {
      type: Function,
      default: () => {}
    },
    selectedRowsCount: {
      type: Number,
      default: 0
    },
    rowsMetadata: {
      type: Array,
      default: () => []
    },
    // return value which will be displayed without slot & used in sort function
    valueParser: {
      type: Function,
      default: null
    },
    currentPage: {
      type: Number,
      default: 1
    },
    totalRowsCount: {
      type: Number,
      default: 0
    },
    perPage: {
      type: Number,
      default: 20
    },
    loading: Boolean,
    editable: Boolean
  },
  data() {
    return {
      sortingOrders,
      buffer: 260,
      headerRowHeight: 52,
      rowHeight: 30,
      resizerClasses: ['resizer', 'resizer--header'],
      numerationKey: 'numeration'
    };
  },
  computed: {
    numerationColumn() {
      return {
        cells: Array.from(
          { length: this.rowsCount },
          (_, index) => this.currentPage * this.perPage - this.perPage + index + 1
        ),
        [this.uniqueKey]: this.numerationKey,
        columnPosition: -1
      };
    },
    localPinnedColumns() {
      return [this.numerationColumn, ...this.pinnedColumns];
    },
    tableColumns() {
      return [
        ...this.localPinnedColumns,
        ...this.columns
      ];
    },
    tableHeaders() {
      return [
        ...this.pinnedHeaders,
        ...this.headers
      ];
    },
    rowsCount() {
      return this.rowsMetadata.length;
    },
    colIds() {
      return this.tableColumns.reduce((acc, col) => {
        if (col.columnPosition !== undefined) {
          acc[col.columnPosition] = col[this.uniqueKey];
        }

        return acc;
      }, []);
    },
    isNoData() {
      return !this.loading && !this.rowsCount;
    },
    showTable() {
      return !this.loading || this.rowsCount;
    },
    showPagination() {
      return this.totalRowsCount > PER_PAGE_MAX;
    },
    tableHeight() {
      if (this.isNoData) {
        return '100%';
      }

      return this.loading && !this.rowsCount ? `${this.maxHeight}px` : 'auto';
    }
  },
  watch: {
    headers() {
      this.initColumnsResizer(true);
    },
    columns() {
      this.initColumnsResizer(true);

      if (!this.scrollObserving) {
        this.$nextTick(this.initObserver);
      }
    },
    rowsCount() {
      this.resetScroll();
    },
    rowsMetadata() {
      this.initSelectedRows();
    },
    selectedRows: {
      handler(val) {
        this.rowsMetadata.forEach((_, rowIndex) => {
          this.$set(this.rowsMetadata[rowIndex], 'cached', val[rowIndex] ?? false);
        });
      },
      deep: true
    }
  },
  mounted() {
    this.initColumnsResizer();
    this.initSelectedRows();
  },
  methods: {
    async initColumnsResizer(isUpdate = false) {
      await this.$nextTick();

      this.initResize({
        tableId: this.id,
        colSelector: '.report-table__cell--resizable',
        minWidth: this.columnMinWidth,
        lazyResize: true,
        initialColSizes: isUpdate ? [] : this.initialColumnSizes,
        resizerHeight: '100%',
        updateSizeCallback: ({ index, width }) => {
          // ATTENTION!!! update column size directly for pinned columns
          // to sync dynamic col sizes with permanent headers
          const colId = this.colIds[index];
          const sizesMap = this.$refs.scrollContainer.vscrollData.sizes;
          const isPinned = this.isPinnedColumn(colId);

          if (isPinned) {
            this.$set(sizesMap, colId, width);
          }

          this.updateColumnSizes({
            [this.uniqueKey]: colId,
            width
          });
        }
      });
    },
    initSelectedRows() {
      const selected = this.rowsMetadata.reduce((acc, row, index) => {
        if (row.enabled) {
          acc[index] = row.enabled;
        }

        return acc;
      }, {});

      this.setSelectedRows(selected);
    },
    resetScroll() {
      if (!this.$refs.scrollContainer) {
        return;
      }

      this.$refs.scrollContainer.$el.scrollTop = 0;
      this.$refs.scrollContainer.$el.scrollLeft = 0;
    },
    scrollToIndex(index) {
      const { scrollToItem } = this.$refs.scrollContainer;

      scrollToItem(index);
    },
    //pagination
    handlePageChange(page) {
      this.$emit('page-change', page);

      this.resetScroll();
    },
    handlePerPageChange(pageSize) {
      this.$emit('per-page-change', pageSize);
    },
    // sort
    updateLocalSort(key) {
      return {
        key,
        order: this.getNewLocalSortStatus()
      };
    },
    getNewLocalSortStatus() {
      return this.sortOrder === this.sortingOrders.ASCENDING
        ? this.sortingOrders.DESCENDING
        : this.sortingOrders.ASCENDING;
    },
    getColumnSortCallback(event, header) {
      const classList = event.target.classList;
      const isResizerClass = this.resizerClasses.some(classItem => classList.contains(classItem));
      const isDisabled = header.style === DISABLED_STYLE;
      const isSelection = window.getSelection().toString();

      if (isResizerClass || isDisabled || isSelection) {
        return null;
      }

      return this.$emit('sort', {
        callback: () => this.updateLocalSort(header[this.headerUniqueKey])
      });
    },
    // table configuration
    isPinnedColumn(key) {
      return this.localPinnedColumns.some(column => column[this.uniqueKey] === key);
    },
    getColStyle(position) {
      return {
        width: (this.colSizes[position] ?? this.columnWidth) + 'px'
      };
    },
    calcUpperHeaderWidth(startIndex, position, headers) {
      const firstHeader = headers[startIndex];

      if (!firstHeader.upperHeader) {
        return;
      }

      let endIndex = startIndex;

      for (let i = startIndex; i <= headers.length - 1; i++) {
        const header = headers[i];
        const isSameHeader = firstHeader.upperHeader?.[this.uniqueKey] === header?.upperHeader?.[this.uniqueKey];
        const isNextDifferent = header?.upperHeader?.[this.uniqueKey] !== headers[i + 1]?.upperHeader?.[this.uniqueKey];

        if (isSameHeader && isNextDifferent) {
          endIndex = i;
          break;
        }
      }

      let upperHeaderWidth = 0;

      for (let i = startIndex; i <= endIndex; i++) {
        const currentPosition = headers[i].columnPosition;

        upperHeaderWidth += this.colSizes[currentPosition] || this.columnWidth;
      }

      return upperHeaderWidth - 1 + 'px';
    },
    isLastDouble(headerIndex, position, headers) {
      const header = headers[headerIndex];

      if (!header.upperHeader) {
        return false;
      }

      const nextHeader = headers[headerIndex + 1];

      return header.upperHeader?.[this.uniqueKey] !== nextHeader?.upperHeader?.[this.uniqueKey];
    },
    isSameHeader(headerIndex, position, headers) {
      const header = headers[headerIndex];

      if (!header.upperHeader) {
        return false;
      }

      const prevHeader = headers[headerIndex - 1];

      if (!prevHeader) {
        return false;
      }

      return header.upperHeader?.[this.uniqueKey] === prevHeader?.upperHeader?.[this.uniqueKey];
    },
    // context menu
    handleHeaderContextMenu(event, context) {
      this.$refs.headerContextMenu.show({ event, context });
    },
    handleBodyContextMenu(event, context) {
      this.$refs.bodyContextMenu.show({ event, context });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/style/components/report/table/index.scss';
</style>
