<template>
  <SlGroupDropdownOption
    :title="$t('Web.Report.Configuration')"
    class="forecast-configuration-category"
    returnable
    @back="$emit('back')"
  >
    <ForecastWidgets
      :widgets="widgets"
      column
    />
  </SlGroupDropdownOption>
</template>

<script>
import ForecastWidgets from '@/components/Report/Toolbar/Forecast/ForecastWidgets.vue';

export default {
  name: 'ConfigurationCategory',
  components: {
    ForecastWidgets
  },
  props: {
    widgets: {
      type: Array,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.forecast-configuration-category {
  &::v-deep .sl-group-dropdown-option__content {
    padding-right: 12px;
    padding-left: 12px;
  }
}
</style>