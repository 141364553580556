var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"reportTable",staticClass:"report-table-wrapper",class:{
    'report-table-wrapper--no-data': _vm.isNoData,
    'report-table-wrapper--pagination': _vm.showPagination
  },style:({
    height: _vm.tableHeight,
    maxHeight: (_vm.maxHeight + "px")
  })},[_c('HeaderContextMenu',{ref:"headerContextMenu"}),_c('BodyContextMenu',{ref:"bodyContextMenu"}),_c('SlOverlay',{attrs:{"show":_vm.loading}}),(_vm.showTable)?_c('div',{staticClass:"report-table",attrs:{"id":_vm.id}},[_c('DynamicScroller',{key:_vm.rowsCount,ref:"scrollContainer",staticClass:"report-table__scroller",style:({
        maxHeight: (_vm.maxHeight + "px")
      }),attrs:{"id":"report-table-scroller","items":_vm.tableColumns,"key-field":_vm.uniqueKey,"min-item-size":_vm.columnWidth,"direction":"horizontal","buffer":_vm.buffer},nativeOn:{"&scroll":function($event){return _vm.updateScroll($event)}},scopedSlots:_vm._u([{key:"before",fn:function(){return [_c('ReportTableHeader',{attrs:{"scrolled":_vm.hasVerticalScrollbar && !_vm.scrolledToTop}},[_c('ReportTableSideCol',{attrs:{"scrolled-left":_vm.hasHorizontalScrollbar && !_vm.scrolledToLeft,"horizontal":""}},[_c('ReportTableCellHeader',{attrs:{"show-checkbox":!!_vm.selectedRowsCount,"editable":_vm.editable,"numeration":""},scopedSlots:_vm._u([{key:"checkbox",fn:function(){return [_vm._t("header-checkbox")]},proxy:true}],null,true)}),(_vm.pinnedHeaders)?_vm._l((_vm.pinnedHeaders),function(header,headerIndex){return _c('ReportTableCellHeader',{key:header[_vm.headerUniqueKey],style:(_vm.getColStyle(header.columnPosition)),attrs:{"title":header[_vm.headerNameKey],"data-resize":header.columnPosition,"upper-width":_vm.calcUpperHeaderWidth(headerIndex, header.columnPosition, _vm.pinnedHeaders),"is-last-double":_vm.isLastDouble(headerIndex, header.columnPosition, _vm.pinnedHeaders),"is-same-header":_vm.isSameHeader(headerIndex, header.columnPosition, _vm.pinnedHeaders),"header":header,"data-test-id":(_vm.id + "-header-" + (header[_vm.headerUniqueKey])),"resizable":""},nativeOn:{"click":function($event){return _vm.getColumnSortCallback($event, header)},"contextmenu":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handleHeaderContextMenu(
                  $event,
                  {
                    header: header,
                    headerIndex: headerIndex
                  })}}},[_c('div',{staticClass:"report-table__cell-text"},[_c('span',[_vm._v(" "+_vm._s(header[_vm.headerNameKey])+" ")]),(_vm.sortBy === header[_vm.headerUniqueKey])?_c('icon',{staticClass:"size-12 report-table__cell-sort-icon",class:{
                      'report-table__cell-sort-icon--desc': _vm.sortOrder === _vm.sortingOrders.DESCENDING
                    },attrs:{"data":require("@icons/arrow-up.svg")}}):_vm._e()],1)])}):_vm._e()],2),_vm._l((_vm.headers),function(header,headerIndex){return _c('ReportTableCellHeader',{key:header[_vm.headerUniqueKey],style:(_vm.getColStyle(header.columnPosition)),attrs:{"title":header[_vm.headerNameKey],"data-resize":header.columnPosition,"upper-width":_vm.calcUpperHeaderWidth(headerIndex, header.columnPosition, _vm.headers),"is-last-double":_vm.isLastDouble(headerIndex, header.columnPosition, _vm.headers),"is-same-header":_vm.isSameHeader(headerIndex, header.columnPosition, _vm.headers),"header":header,"data-test-id":(_vm.id + "-header-" + (header[_vm.headerUniqueKey])),"resizable":""},nativeOn:{"click":function($event){return _vm.getColumnSortCallback($event, header)},"contextmenu":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handleHeaderContextMenu(
              $event,
              {
                header: header,
                headerIndex: headerIndex
              })}}},[_c('div',{staticClass:"report-table__cell-text"},[_c('span',[_vm._v(" "+_vm._s(header[_vm.headerNameKey])+" ")]),(_vm.sortBy === header[_vm.headerUniqueKey])?_c('icon',{staticClass:"size-12 report-table__cell-sort-icon",class:{
                  'report-table__cell-sort-icon--desc': _vm.sortOrder === _vm.sortingOrders.DESCENDING
                },attrs:{"data":require("@icons/arrow-up.svg")}}):_vm._e()],1)])})],2)]},proxy:true},{key:"default",fn:function(ref){
                var col = ref.item;
                var colIndex = ref.index;
                var active = ref.active;
return [_c('DynamicScrollerItem',{attrs:{"item":col,"active":active,"data-index":colIndex}},[(_vm.isPinnedColumn(col[_vm.uniqueKey]))?_c('ReportTableSideCol',{style:(colIndex && _vm.getColStyle(col.columnPosition))},[(col[_vm.uniqueKey] === _vm.numerationKey)?_c('ReportTableCell',{attrs:{"numeration":""}}):_c('ReportTableCell')],1):_c('ReportTableBodyCol',{style:(_vm.getColStyle(col.columnPosition)),attrs:{"column":col,"selected-rows":_vm.selectedRows,"rows-metadata":_vm.rowsMetadata,"unique-key":_vm.uniqueKey,"editable":_vm.tableHeaders[colIndex - 1] && _vm.tableHeaders[colIndex - 1].editable,"data-test-id":(_vm.id + "-col-" + (_vm.tableHeaders[colIndex - 1] && _vm.tableHeaders[colIndex - 1][_vm.headerUniqueKey]))},on:{"contextmenu":_vm.handleBodyContextMenu}})],1)]}},{key:"after",fn:function(){return [_c('ReportTableSideCol',_vm._l((_vm.numerationColumn.cells),function(cellValue,cellIndex){return _c('ReportTableCell',{key:cellIndex,attrs:{"editable":_vm.rowsMetadata[cellIndex].editable && _vm.editable,"hovered":_vm.lastHoveredIndex === cellIndex,"selected":_vm.rowsMetadata[cellIndex].enabled || _vm.rowsMetadata[cellIndex].cached,"data-row-index":cellIndex,"tabindex":cellIndex - 1,"numeration":""},scopedSlots:_vm._u([{key:"checkbox",fn:function(){return [_vm._t("row-checkbox",null,null,{
                  rowIndex: cellIndex,
                  toggle: function (value) { return _vm.updateSelection(cellIndex, value); }
                })]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(cellValue)+" ")])}),1),_c('ReportTableSideCol',{attrs:{"scrolled-left":_vm.hasHorizontalScrollbar && !_vm.scrolledToLeft,"horizontal":""}},_vm._l((_vm.pinnedColumns),function(col,colIndex){return _c('ReportTableBodyCol',{key:col.columnPosition,style:(_vm.getColStyle(col.columnPosition)),attrs:{"column":col,"selected-rows":_vm.selectedRows,"rows-metadata":_vm.rowsMetadata,"unique-key":_vm.uniqueKey,"editable":_vm.tableHeaders[colIndex].editable,"data-test-id":(_vm.id + "-col-" + (_vm.tableHeaders[colIndex][_vm.headerUniqueKey]))},on:{"contextmenu":_vm.handleBodyContextMenu}})}),1)]},proxy:true}],null,false,1395824873)})],1):_vm._e(),(_vm.showPagination)?_c('SlTablePagination',{attrs:{"value":_vm.currentPage,"total-count":_vm.totalRowsCount,"selected":_vm.selectedRowsCount,"per-page":_vm.perPage,"disabled":_vm.loading,"show-per-page":false},on:{"input":_vm.handlePageChange,"per-page":_vm.handlePerPageChange},scopedSlots:_vm._u([(_vm.editable)?{key:"metadata",fn:function(){return [_c('ReportTableInfoButton')]},proxy:true}:null],null,true)}):_vm._e(),(_vm.isNoData)?_vm._t("no-data"):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }