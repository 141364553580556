export const controlType = {
  checkbox: 'checkbox',
  button: 'button',
  numberInput: 'numberInput',
  select: 'select',
  comboBox: 'comboData'
};

export const widgetKeys = {
  REPORT_TYPE: 'ReportType',
  CUSTOM_FIELD: 'CustomField',
  FORECAST_VERSION: 'ForecastVersion',
  ARCHIVE_PERIOD: 'ArchivePeriod',
  AGGREGATE_KEYS: 'AggregateKeys',
  DISPLAY_IN: 'DisplayIn',
  SUM_BY: 'SumBy',
  ACTUAL_SALES_PERIODS_CHECKBOX: 'ActualSalesPeriodsCheckbox',
  ACTUAL_SALES_PERIODS: 'ActualSalesPeriods',
  CONSTRAINED: 'Constrained',
  TURNOVER: 'Turnover',
  IMPORT_CHANGES: 'ImportToXlsx'
};

export const contentWidgetKeys = [
  widgetKeys.REPORT_TYPE,
  widgetKeys.CUSTOM_FIELD,
  widgetKeys.FORECAST_VERSION,
  widgetKeys.ARCHIVE_PERIOD,
  widgetKeys.DISPLAY_IN
];

export const configurationWidgetKeys = [
  widgetKeys.SUM_BY,
  widgetKeys.ACTUAL_SALES_PERIODS_CHECKBOX,
  widgetKeys.ACTUAL_SALES_PERIODS,
  widgetKeys.CONSTRAINED,
  widgetKeys.TURNOVER
];

export const actualSalesKey = 'Plan_fact';
