<template>
  <SlDropdown
    v-bind="$attrs"
    placement="bottom-end"
  >
    <template #target>
      <SlButton
        variant="tertiary"
        color="grey"
        size="xs"
        icon
        @click.stop
      >
        <icon
          data="@icons/vertical-dots.svg"
          class="size-16 color-grey"
        />
      </SlButton>
    </template>
    <template #content>
      <SlDropdownOption
        v-for="action in allowedActions"
        :key="action.label"
        select-close
        @select="callAction(action)"
      >
        <template #prepend>
          <icon
            v-if="action.icon"
            class="fill-off size-16 color-grey-50"
            :data="require(`@icons/${action.icon}.svg`)"
          />
        </template>
        {{ action.label }}
      </SlDropdownOption>
    </template>
  </SlDropdown>
</template>

<script>
import { modal } from '@/mixins/modal';
import modalsId from '@/config/shared/modalsId.config';
import { namespaceByRoute } from '@/config/report';

export default {
  name: 'FilterActionsDropdown',
  mixins: [modal],
  props: {
    filter: {
      type: Object,
      required: true
    },
    filterIndex: {
      type: Number,
      required: true
    },
    actionCallback: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      actionsConfig: [
        {
          label: this.$t('Web.Filters.MoveToStart'),
          icon: 'double_chevron_left',
          action: this.handleMoveToStart
        },
        {
          label: this.$t('Web.Filters.Edit'),
          icon: 'pen',
          action: ({ id }) => this.handleEditFilter(id),
          actionCallback: this.actionCallback
        },
        {
          label: this.$t('Web.Filters.Duplicate'),
          icon: 'copy',
          action: ({ id }) => this.duplicateFilter(id),
          actionCallback: this.actionCallback
        },
        {
          label: this.$t('Web.Filters.Delete'),
          icon: 'trash',
          action: this.handleDeleteFilter,
          actionCallback: this.actionCallback
        }
      ]
    };
  },
  computed: {
    reportNamespace() {
      return namespaceByRoute[this.$sl_routeName];
    },
    filtersList() {
      return this.$store.state[`${this.reportNamespace}`].filters_list;
    },
    activeFilterId() {
      return this.$store.state[`${this.reportNamespace}`].active_filter_id;
    },
    allowedActions() {
      return this.actionsConfig.filter((_, actionIndex) => !(this.filterIndex === 1 && actionIndex === 0));
    }
  },
  methods: {
    callAction({ action, actionCallback }) {
      action(this.filter);
      actionCallback?.();
    },
    duplicateFilter(...args) {
      this.$store.dispatch(`${this.reportNamespace}/duplicateFilter`, ...args);
    },
    deleteFilter(...args) {
      this.$store.dispatch(`${this.reportNamespace}/deleteFilter`, ...args);
    },
    updateFiltersOrder(...args) {
      this.$store.dispatch(`${this.reportNamespace}/updateFiltersOrder`, ...args);
    },
    handleDeleteFilter({ id, name, closable }) {
      if (!closable) {
        return;
      }

      this.showModal(modalsId.SL_CONFIRM_MODAL, {
        title: this.$t('Web.Modals.ActionConfirm.TitleDeleteFilter', { 1: name }),
        text: this.$t('Web.Modals.Delete.Description'),
        icon: 'style_warning_double',
        confirmText: this.$t('Web.Modals.ActionConfirm.ButtonDelete'),
        confirmCallback: () => this.deleteFilter(id)
      });
    },
    handleEditFilter(filterId) {
      this.showModal(modalsId.REPORT_FILTER, { filterId });
    },
    handleMoveToStart(filter) {
      // wait for dropdown to close
      setTimeout(() => {
        this.updateFiltersOrder([
          filter,
          ...this.filtersList.filter(({ id }) => id !== filter.id)
        ]);
      }, 50);
    }
  }
};
</script>