var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"report-table-dropdown"},[_vm._l((_vm.containerTypes),function(entity){return [(_vm.column[_vm.uniqueKey] === _vm.collectionsConfig[entity].key)?_c('SlTableSelect',{key:entity,attrs:{"value":_vm.cell.val,"options":_vm.addNoneOption(_vm.collectionOptionsByType[entity]),"text-value":_vm.textValue,"disabled":_vm.isCollectionDisabled(entity),"clearable":_vm.isOverride,"foreground":_vm.cell.foreground,"type":"row","editable-bg":false,"allow-empty":_vm.isOverride,"max-height":_vm.maxHeight,"open-direction":"auto-height","focus-visible":"","searchable":""},on:{"input":_vm.handleChangeSelect},scopedSlots:_vm._u([{key:"clear",fn:function(ref){
var clear = ref.clear;
return [_c('SlButton',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.getTooltip(_vm.$t('Main.CheckboxClearOv'))),expression:"getTooltip($t('Main.CheckboxClearOv'))"}],staticClass:"sl-select__clear",attrs:{"size":"xs","variant":"tertiary","color":"grey","icon":""},on:{"mousedown":function($event){$event.preventDefault();$event.stopPropagation();return clear($event)}}},[_c('icon',{staticClass:"size-16",attrs:{"data":require("@icons/close.svg")}})],1)]}},{key:"afterList",fn:function(){return [_c('div',{staticClass:"report-table-dropdown__action",on:{"click":function($event){return _vm.handleCreateEntity(entity)}}},[_vm._v(" "+_vm._s(_vm.collectionsConfig[entity].label)+" ")])]},proxy:true}],null,true)}):_vm._e()]}),(_vm.isDC)?_c('SlTableSelect',{attrs:{"value":_vm.cell.val,"options":_vm.addNoneOption(_vm.filterDcOptions(_vm.getOptions(_vm.DCOptions))),"text-value":_vm.textValue,"disabled":_vm.disabled,"loading":_vm.isLoading,"show-no-options":_vm.isLoaded,"clearable":_vm.isOverride,"foreground":_vm.cell.foreground,"type":"row","editable-bg":false,"allow-empty":_vm.isOverride,"max-height":_vm.maxHeight,"open-direction":"auto-height","focus-visible":"","searchable":""},on:{"open":_vm.loadDCOptions,"input":_vm.handleChangeSelect},scopedSlots:_vm._u([{key:"clear",fn:function(ref){
var clear = ref.clear;
return [_c('SlButton',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.getTooltip(_vm.$t('Main.CheckboxClearOv'))),expression:"getTooltip($t('Main.CheckboxClearOv'))"}],staticClass:"sl-select__clear",attrs:{"size":"xs","variant":"tertiary","color":"grey","icon":""},on:{"mousedown":function($event){$event.preventDefault();$event.stopPropagation();return clear($event)}}},[_c('icon',{staticClass:"size-16",attrs:{"data":require("@icons/close.svg")}})],1)]}}],null,false,3466134622)}):_vm._e(),(_vm.isSupplier)?_c('SlTableSelect',{attrs:{"value":_vm.cell.val,"options":_vm.addNoneOption(_vm.getOptions(_vm.supplierOptions)),"text-value":_vm.textValue,"loading":_vm.isLoading,"show-no-options":_vm.isLoaded,"clearable":_vm.isOverride,"foreground":_vm.cell.foreground,"max-height":_vm.maxHeight,"open-direction":"auto-height","type":"row","editable-bg":false,"allow-empty":_vm.isOverride,"focus-visible":"","searchable":""},on:{"open":_vm.loadSupplierOptions,"input":_vm.handleChangeSelect},scopedSlots:_vm._u([{key:"clear",fn:function(ref){
var clear = ref.clear;
return [_c('SlButton',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.getTooltip(_vm.$t('Main.CheckboxClearOv'))),expression:"getTooltip($t('Main.CheckboxClearOv'))"}],staticClass:"sl-select__clear",attrs:{"size":"xs","variant":"tertiary","color":"grey","icon":""},on:{"mousedown":function($event){$event.preventDefault();$event.stopPropagation();return clear($event)}}},[_c('icon',{staticClass:"size-16",attrs:{"data":require("@icons/close.svg")}})],1)]}}],null,false,3466134622)}):_vm._e(),(_vm.isOrderingDays)?_c('SlTableSelect',{ref:"orderingDaysSelect",attrs:{"options":_vm.getOptions(_vm.orderingDayOptions),"track-by":"id","text-value":_vm.textValue,"loading":_vm.isLoading,"show-no-options":_vm.isLoaded,"clearable":_vm.isOverride,"foreground":_vm.cell.foreground,"type":"row","editable-bg":false,"close-on-select":false,"max-height":_vm.maxHeight,"open-direction":"auto-height","allow-empty":"","focus-visible":"","multiple":""},on:{"open":_vm.loadOrderingDayOptions,"close":_vm.handleChangeOrderingDays},scopedSlots:_vm._u([{key:"selection",fn:function(){return [_vm._v(" "+_vm._s(' ')+" ")]},proxy:true},{key:"clear",fn:function(){return [_c('SlButton',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.getTooltip(_vm.$t('Main.CheckboxClearOv'))),expression:"getTooltip($t('Main.CheckboxClearOv'))"}],staticClass:"sl-select__clear",attrs:{"size":"xs","variant":"tertiary","color":"grey","icon":""},on:{"mousedown":function($event){$event.preventDefault();$event.stopPropagation();return _vm.handleResetOrderingDays($event)}}},[_c('icon',{staticClass:"size-16",attrs:{"data":require("@icons/close.svg")}})],1)]},proxy:true},{key:"selectOption",fn:function(ref){
var option = ref.option;
var selected = ref.selected;
return [_c('div',{staticClass:"ordering-days__option"},[_c('SlCheckbox',{attrs:{"value":selected},nativeOn:{"click":function($event){$event.preventDefault();}}}),_c('span',[_vm._v(" "+_vm._s(option.name)+" ")])],1)]}}],null,false,422446855),model:{value:(_vm.selectedOrderingDays),callback:function ($$v) {_vm.selectedOrderingDays=$$v},expression:"selectedOrderingDays"}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }